import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  useMemo,
} from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import InputLabel from "@material-ui/core/InputLabel";
import DragHandleIcon from "@material-ui/icons/DragHandle";
const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "white",
    padding: "20px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    boxShadow: "0 0 0 0 rgba(255, 105, 135, .3)",
    color: "rgba(0, 0, 0, 0.87)",
  },
  formControl: {
    minWidth: 100,
  },
  textField: {
    minWidth: 100,
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Quote = ({ quote, index, cb }) => {
  const classes = useStyles();
  const handleDelete = useCallback(() => {
    cb(quote, "delete");
  }, [cb, quote]);

  const onBlur = useCallback(
    (event) => {
      if (event.target.value !== quote.columnName) {
        const newQuote = {
          ...quote,
          columnName: event.target.value,
        };
        cb(newQuote, "edit");
      }
    },
    [cb, quote]
  );

  const selectOnChange = useCallback(
    (event) => {
      const newQuote = {
        ...quote,
        type: event.target.value,
      };
      cb(newQuote, "edit");
    },
    [cb, quote]
  );

  return (
    <Draggable draggableId={quote.id} index={index}>
      {(provided) => (
        <div
          className={classes.main}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <DragHandleIcon style={{ marginRight: 30 }} />

          <Grid container spacing={10}>
            <Grid item xs={1}>
              <span style={{ lineHeight: 2.5 }}>{index + 1}</span>
            </Grid>
            <Grid item xs>
              {/* <Paper className={classes.paper}>{quote.columnName}</Paper> */}
              <TextField
                className={classes.textField}
                defaultValue={quote.columnName}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item xs>
              <FormControl className={classes.formControl}>
                <Select
                  onChange={selectOnChange}
                  defaultValue={quote.type}
                  id="grouped-select"
                >
                  <MenuItem value={"number"}>number</MenuItem>
                  <MenuItem value={"string"}>string</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs>
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleDelete}
              ></Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Draggable>
  );
};

const QuoteList = ({ data, cb }) => {
  return data.map((quote, index: number) => (
    <Quote quote={quote} index={index} key={quote.id} cb={cb} />
  ));
};

export const EditProject = ({
  record,
  headers,
  type,
  callback,
}: {
  record: any;
  headers: any;
  type: any;
  callback: any;
}) => {
  const [data, setData] = useState(Array());
  const API = new LoopbackAPI();
  const classes = useStyles();
  const [namePj, setNamePj] = useState(
    record && record.name ? record.name : ""
  );

  useEffect(() => {
    const newData = headers.map((item, index) => {
      
      if (!item["id"]) {
        item["id"] = index.toString();
      }
      return item;
    });
    setData(newData);
  }, [headers]);

  const handleCreate = useCallback(() => {
    var i = 0;
    while (data.map((i) => i.id).includes(`${i}`)) {
      i++;
    }
    const newOb = {
      columnName: "",
      type: "number",
      id: i.toString(),
    };
    setData([...data, newOb]);
  }, [data]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }
      const quotes = reorder(
        data,
        result.source.index,
        result.destination.index
      );
      setData(quotes);
    },
    [data]
  );

  const cb = useCallback(
    (param, type) => {
      if (type === "edit") {
        const newData = data.map((item) => {
          if (item.id === param.id) return param;
          return item;
        });
        callback(newData);
        setData(newData);
      } else {
        const newData = data.filter((item) => item.id !== param.id);
        callback(newData);
        setData(newData);
      }
    },
    [callback, data]
  );

  const handleSave = useCallback(async () => {
    const dataPath = { ...record, name: namePj, headers: data };
    await API.projects
      .projectPrototypePatchAttributes(record.id, dataPath)
      .then((result) => {
        if (result && result.data && result.data.headers) {
          window.location.reload(false);
        }
      })
      .catch((err) => {});
  }, [API.projects, data, namePj, record]);

  const onChangeTextName = useCallback((event) => {
    setNamePj(event.target.value);
  }, []);

  return (
    <Fragment>
      {useMemo(
        () =>
          record &&
          record.name && (
            <Grid container style={{ padding: 20 }} spacing={10}>
              <Grid item xs>
                <TextField
                  label="name project"
                  onBlur={onChangeTextName}
                  className={classes.textField}
                  style={{}}
                  defaultValue={record.name}
                />
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
            </Grid>
          ),
        [classes.textField, onChangeTextName, record]
      )}

      {useMemo(
        () =>
          data.length > 0 && (
            <Grid
              container
              style={{ paddingLeft: 74, paddingRight: 20 }}
              spacing={10}
            >
              <Grid item xs={1}>
                Id
              </Grid>
              <Grid item xs>
                Column Name
              </Grid>
              <Grid item xs>
                Type
              </Grid>

              <Grid item xs></Grid>
            </Grid>
          ),
        [data.length]
      )}

      {useMemo(
        () => (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <QuoteList data={data} cb={cb} />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ),
        [cb, data, onDragEnd]
      )}

      {useMemo(
        () => (
          <Button
            variant="contained"
            color="default"
            startIcon={<AddIcon />}
            style={{
              textTransform: "none",
              marginRight: 20,
              marginTop: 20,
              maxWidth: 100,
            }}
            onClick={handleCreate}
          >
            Create
          </Button>
        ),
        [handleCreate]
      )}

      {useMemo(
        () =>
          !type && (
            <Button
              variant="contained"
              color="default"
              // startIcon={<AddIcon />}
              style={{
                textTransform: "none",
                marginRight: 20,
                marginTop: 20,
                maxWidth: 100,
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          ),
        [handleSave, type]
      )}
    </Fragment>
  );
};
