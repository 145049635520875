// in src/customRoutes.js
import * as React from "react";
import { Route } from 'react-router-dom';
import { ForgotPasswordPage } from "../pages/forgotpassword/forgotpassword";
import { PinCodePage } from "../pages/pincode/pincode";
import { ChangePasswordPage } from "../pages/changepassword/changepassword";
import { ImportCsv } from "../pages/csv/import";
import { ApiKey } from "../pages/apikey/apikey";

export default [
    <Route exact path="/ForgotPasswordPage" component={ForgotPasswordPage} noLayout/>,
    <Route exact path="/PinCodePage" component={PinCodePage} noLayout/>,
    <Route exact path="/ChangePasswordPage" component={ChangePasswordPage} noLayout/>,
    <Route exact path="/CSV/import" component={ImportCsv} />,
    <Route exact path="/apikey" component={ApiKey} />,

];