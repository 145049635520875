import * as React from "react";
import { useRefresh, useNotify, useUnselectAll } from "react-admin";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import Button from "@material-ui/core/Button";
import { OrderStatus } from "../../react-admin-loopback/api/shared-constants";
import CancelIcon from "@material-ui/icons/Cancel";
const SetActiveButtons = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const API = new LoopbackAPI();

  const updateMany = (status) => () => {
    const data = {
      isActive: status
    };
    Promise.all(
      selectedIds.map((id) =>
        API.accounts.accountPrototypePatchAttributes(id, data as any)
      )
    ).then(() => {
      notify("Success");
      unselectAll("Accounts");
      refresh();
    });
  };

  return (
    <div>
      <Button
        className="m-1"
        variant="contained"
        onClick={updateMany(true)}
        color="primary"
      >
        {selectedIds.length <= 1 ? "Set active" : "Set Active All"}
      </Button>
      <Button className="m-1" variant="contained" onClick={updateMany(false)}>
        {selectedIds.length <= 1 ? "Set inactive" : "Set inactive All"}
      </Button>
    </div>
  );
};

export default SetActiveButtons;
