import React, { Component } from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  PasswordInput,
  RadioButtonGroupInput,
  SelectInput,
  showNotification,
  SimpleForm,
  TextInput,
  useRefresh,
  ReferenceInput,
  EditButton,
  useRedirect,
  TextField,
  FormDataConsumer,
  Filter,
  SearchInput,
  CreateButton,
  useTranslate,
  useNotify,
} from "react-admin";
import "../accounts/account.scss";
import { parse } from "query-string";

export const RoleMappingsCreate = (props: any) => {
  const { principalId: post_id_string } = parse(props.location.search);
  const principalId = post_id_string;

  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = ({ data }) => {
    redirect("/Accounts");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm initialValues={{ principalId }}>
        <TextInput source="principalType" initialValue="USER" />
        <ReferenceInput source="principalId" reference="Accounts">
          <SelectInput disabled={principalId ? true : null} optionText="lastName" />
        </ReferenceInput>
        <ReferenceInput source="roleId" reference="Roles">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
