/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type XAny = any;

export interface Role {
  id?: number;
  name: string;
  description?: string;
  created?: string;
  modified?: string;
}

/**
 * Map principals to roles
 */
export interface RoleMapping {
  id?: number;

  /** The principal type, such as USER, APPLICATION, ROLE, or user model name in case of multiple user models */
  principalType?: string;
  principalId?: string;
  roleId?: number;
}

export interface AccountToken {
  id: string;

  /** time to live in seconds (2 weeks by default) */
  ttl?: number;

  /** Array of scopes granted to this access token. */
  scopes?: string[];
  created?: string;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Project {
  id: string;
  name: string;
  headers?: object[];
  accountId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Batch {
  id: string;
  revision?: number;
  size?: number;
  originalFile?: string;
  transferStatus?: string;
  projectId?: string;
  uploaderId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface Account {
  id: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  isActive?: boolean;
  type?: string;
  apiKey: string;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export interface TrackRecord {
  id?: number;

  /** Record column */
  column1?: string;

  /** Record column */
  column2?: string;

  /** Record column */
  column3?: string;

  /** Record column */
  column4?: string;

  /** Record column */
  column5?: string;

  /** Record column */
  column6?: string;

  /** Record column */
  column7?: string;

  /** Record column */
  column8?: string;

  /** Record column */
  column9?: string;

  /** Record column */
  column10?: string;

  /** Record column */
  column11?: string;

  /** Record column */
  column12?: string;

  /** Record column */
  column13?: string;

  /** Record column */
  column14?: string;

  /** Record column */
  column15?: string;

  /** Record column */
  column16?: string;

  /** Record column */
  column17?: string;

  /** Record column */
  column18?: string;

  /** Record column */
  column19?: string;

  /** Record column */
  column20?: string;

  /** Record column */
  column21?: string;

  /** Record column */
  column22?: string;

  /** Record column */
  column23?: string;

  /** Record column */
  column24?: string;

  /** Record column */
  column25?: string;

  /** Record column */
  column26?: string;

  /** Record column */
  column27?: string;

  /** Record column */
  column28?: string;

  /** Record column */
  column29?: string;

  /** Record column */
  column30?: string;

  /** Record column */
  column31?: string;

  /** Record column */
  column32?: string;

  /** Record column */
  column33?: string;

  /** Record column */
  column34?: string;

  /** Record column */
  column35?: string;

  /** Record column */
  column36?: string;

  /** Record column */
  column37?: string;

  /** Record column */
  column38?: string;

  /** Record column */
  column39?: string;

  /** Record column */
  column40?: string;

  /** Record column */
  column41?: string;

  /** Record column */
  column42?: string;

  /** Record column */
  column43?: string;

  /** Record column */
  column44?: string;

  /** Record column */
  column45?: string;

  /** Record column */
  column46?: string;

  /** Record column */
  column47?: string;

  /** Record column */
  column48?: string;

  /** Record column */
  column49?: string;

  /** Record column */
  column50?: string;

  /** Record column */
  column51?: string;

  /** Record column */
  column52?: string;

  /** Record column */
  column53?: string;

  /** Record column */
  column54?: string;

  /** Record column */
  column55?: string;

  /** Record column */
  column56?: string;

  /** Record column */
  column57?: string;

  /** Record column */
  column58?: string;

  /** Record column */
  column59?: string;

  /** Record column */
  column60?: string;

  /** Record column */
  column61?: string;

  /** Record column */
  column62?: string;

  /** Record column */
  column63?: string;

  /** Record column */
  column64?: string;

  /** Record column */
  column65?: string;

  /** Record column */
  column66?: string;

  /** Record column */
  column67?: string;

  /** Record column */
  column68?: string;

  /** Record column */
  column69?: string;

  /** Record column */
  column70?: string;

  /** Record column */
  column71?: string;

  /** Record column */
  column72?: string;

  /** Record column */
  column73?: string;

  /** Record column */
  column74?: string;

  /** Record column */
  column75?: string;

  /** Record column */
  column76?: string;

  /** Record column */
  column77?: string;

  /** Record column */
  column78?: string;

  /** Record column */
  column79?: string;

  /** Record column */
  column80?: string;

  /** Record column */
  column81?: string;

  /** Record column */
  column82?: string;

  /** Record column */
  column83?: string;

  /** Record column */
  column84?: string;

  /** Record column */
  column85?: string;

  /** Record column */
  column86?: string;

  /** Record column */
  column87?: string;

  /** Record column */
  column88?: string;

  /** Record column */
  column89?: string;

  /** Record column */
  column90?: string;

  /** Record column */
  column91?: string;

  /** Record column */
  column92?: string;

  /** Record column */
  column93?: string;

  /** Record column */
  column94?: string;

  /** Record column */
  column95?: string;

  /** Record column */
  column96?: string;

  /** Record column */
  column97?: string;

  /** Record column */
  column98?: string;

  /** Record column */
  column99?: string;

  /** Record column */
  column100?: string;

  /** Record column */
  column101?: string;

  /** Record column */
  column102?: string;

  /** Record column */
  column103?: string;

  /** Record column */
  column104?: string;

  /** Record column */
  column105?: string;

  /** Record column */
  column106?: string;

  /** Record column */
  column107?: string;

  /** Record column */
  column108?: string;

  /** Record column */
  column109?: string;

  /** Record column */
  column110?: string;

  /** Record column */
  column111?: string;

  /** Record column */
  column112?: string;

  /** Record column */
  column113?: string;

  /** Record column */
  column114?: string;

  /** Record column */
  column115?: string;

  /** Record column */
  column116?: string;

  /** Record column */
  column117?: string;

  /** Record column */
  column118?: string;

  /** Record column */
  column119?: string;

  /** Record column */
  column120?: string;

  /** Record column */
  column121?: string;

  /** Record column */
  column122?: string;

  /** Record column */
  column123?: string;

  /** Record column */
  column124?: string;

  /** Record column */
  column125?: string;

  /** Record column */
  column126?: string;

  /** Record column */
  column127?: string;

  /** Record column */
  column128?: string;

  /** Record column */
  column129?: string;

  /** Record column */
  column130?: string;

  /** Record column */
  column131?: string;

  /** Record column */
  column132?: string;

  /** Record column */
  column133?: string;

  /** Record column */
  column134?: string;

  /** Record column */
  column135?: string;

  /** Record column */
  column136?: string;

  /** Record column */
  column137?: string;

  /** Record column */
  column138?: string;

  /** Record column */
  column139?: string;

  /** Record column */
  column140?: string;

  /** Record column */
  column141?: string;

  /** Record column */
  column142?: string;

  /** Record column */
  column143?: string;

  /** Record column */
  column144?: string;

  /** Record column */
  column145?: string;

  /** Record column */
  column146?: string;

  /** Record column */
  column147?: string;

  /** Record column */
  column148?: string;

  /** Record column */
  column149?: string;

  /** Record column */
  column150?: string;

  /** Record column */
  column151?: string;

  /** Record column */
  column152?: string;

  /** Record column */
  column153?: string;

  /** Record column */
  column154?: string;

  /** Record column */
  column155?: string;

  /** Record column */
  column156?: string;

  /** Record column */
  column157?: string;

  /** Record column */
  column158?: string;

  /** Record column */
  column159?: string;

  /** Record column */
  column160?: string;

  /** Record column */
  column161?: string;

  /** Record column */
  column162?: string;

  /** Record column */
  column163?: string;

  /** Record column */
  column164?: string;

  /** Record column */
  column165?: string;

  /** Record column */
  column166?: string;

  /** Record column */
  column167?: string;

  /** Record column */
  column168?: string;

  /** Record column */
  column169?: string;

  /** Record column */
  column170?: string;

  /** Record column */
  column171?: string;

  /** Record column */
  column172?: string;

  /** Record column */
  column173?: string;

  /** Record column */
  column174?: string;

  /** Record column */
  column175?: string;

  /** Record column */
  column176?: string;

  /** Record column */
  column177?: string;

  /** Record column */
  column178?: string;

  /** Record column */
  column179?: string;

  /** Record column */
  column180?: string;

  /** Record column */
  column181?: string;

  /** Record column */
  column182?: string;

  /** Record column */
  column183?: string;

  /** Record column */
  column184?: string;

  /** Record column */
  column185?: string;

  /** Record column */
  column186?: string;

  /** Record column */
  column187?: string;

  /** Record column */
  column188?: string;

  /** Record column */
  column189?: string;

  /** Record column */
  column190?: string;

  /** Record column */
  column191?: string;

  /** Record column */
  column192?: string;

  /** Record column */
  column193?: string;

  /** Record column */
  column194?: string;

  /** Record column */
  column195?: string;

  /** Record column */
  column196?: string;

  /** Record column */
  column197?: string;

  /** Record column */
  column198?: string;

  /** Record column */
  column199?: string;

  /** Record column */
  column200?: string;

  /** Record extra columns */
  extraColumns?: object;
  projectId?: string;
  createdAt?: string;
  updatedAt?: string;
  batchId?: string;
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): Promise<HttpResponse<T>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker ? this.securityWorker(this.securityData) : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Fabien-CSV-API
 * @version 1.0.0
 * @baseUrl /api
 * fabien-CSV-API
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  accounts = {
    /**
     * @tags Account
     * @name Account.prototype.__findById__roles
     * @summary Find a related item by id for roles.
     * @request GET:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeFindByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__roles
     * @summary Delete a related item by id for roles.
     * @request DELETE:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeDestroyByIdRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__roles
     * @summary Update a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/{fk}
     */
    accountPrototypeUpdateByIdRoles: (id: string, fk: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__link__roles
     * @summary Add a related item by id for roles.
     * @request PUT:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeLinkRoles: (id: string, fk: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Accounts/${id}/roles/rel/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__unlink__roles
     * @summary Remove the roles relation to an item by id.
     * @request DELETE:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeUnlinkRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles/rel/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__exists__roles
     * @summary Check the existence of roles relation to an item by id.
     * @request HEAD:/Accounts/{id}/roles/rel/{fk}
     */
    accountPrototypeExistsRoles: (id: string, fk: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/roles/rel/${fk}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.prototype.__findById__accessTokens
     * @summary Find a related item by id for accessTokens.
     * @request GET:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeFindByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__accessTokens
     * @summary Delete a related item by id for accessTokens.
     * @request DELETE:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeDestroyByIdAccessTokens: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__accessTokens
     * @summary Update a related item by id for accessTokens.
     * @request PUT:/Accounts/{id}/accessTokens/{fk}
     */
    accountPrototypeUpdateByIdAccessTokens: (id: string, fk: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__projects
     * @summary Find a related item by id for projects.
     * @request GET:/Accounts/{id}/projects/{fk}
     */
    accountPrototypeFindByIdProjects: (id: string, fk: string, params?: RequestParams) =>
      this.request<Project, any>(`/Accounts/${id}/projects/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__projects
     * @summary Delete a related item by id for projects.
     * @request DELETE:/Accounts/{id}/projects/{fk}
     */
    accountPrototypeDestroyByIdProjects: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/projects/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__projects
     * @summary Update a related item by id for projects.
     * @request PUT:/Accounts/{id}/projects/{fk}
     */
    accountPrototypeUpdateByIdProjects: (id: string, fk: string, data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Accounts/${id}/projects/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__findById__batches
     * @summary Find a related item by id for batches.
     * @request GET:/Accounts/{id}/batches/{fk}
     */
    accountPrototypeFindByIdBatches: (id: string, fk: string, params?: RequestParams) =>
      this.request<Batch, any>(`/Accounts/${id}/batches/${fk}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__destroyById__batches
     * @summary Delete a related item by id for batches.
     * @request DELETE:/Accounts/{id}/batches/{fk}
     */
    accountPrototypeDestroyByIdBatches: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/batches/${fk}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__updateById__batches
     * @summary Update a related item by id for batches.
     * @request PUT:/Accounts/{id}/batches/{fk}
     */
    accountPrototypeUpdateByIdBatches: (id: string, fk: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Accounts/${id}/batches/${fk}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__get__roles
     * @summary Queries roles of Account.
     * @request GET:/Accounts/{id}/roles
     */
    accountPrototypeGetRoles: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role[], any>(`/Accounts/${id}/roles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__roles
     * @summary Creates a new instance in roles of this model.
     * @request POST:/Accounts/{id}/roles
     */
    accountPrototypeCreateRoles: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Accounts/${id}/roles`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__roles
     * @summary Deletes all roles of this model.
     * @request DELETE:/Accounts/{id}/roles
     */
    accountPrototypeDeleteRoles: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/roles`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__roles
     * @summary Counts roles of Account.
     * @request GET:/Accounts/{id}/roles/count
     */
    accountPrototypeCountRoles: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/${id}/roles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__get__accessTokens
     * @summary Queries accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens
     */
    accountPrototypeGetAccessTokens: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/Accounts/${id}/accessTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__accessTokens
     * @summary Creates a new instance in accessTokens of this model.
     * @request POST:/Accounts/{id}/accessTokens
     */
    accountPrototypeCreateAccessTokens: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/Accounts/${id}/accessTokens`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__accessTokens
     * @summary Deletes all accessTokens of this model.
     * @request DELETE:/Accounts/{id}/accessTokens
     */
    accountPrototypeDeleteAccessTokens: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/accessTokens`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__accessTokens
     * @summary Counts accessTokens of Account.
     * @request GET:/Accounts/{id}/accessTokens/count
     */
    accountPrototypeCountAccessTokens: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/accessTokens/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__projects
     * @summary Queries projects of Account.
     * @request GET:/Accounts/{id}/projects
     */
    accountPrototypeGetProjects: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Project[], any>(`/Accounts/${id}/projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__projects
     * @summary Creates a new instance in projects of this model.
     * @request POST:/Accounts/{id}/projects
     */
    accountPrototypeCreateProjects: (id: string, data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Accounts/${id}/projects`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__projects
     * @summary Deletes all projects of this model.
     * @request DELETE:/Accounts/{id}/projects
     */
    accountPrototypeDeleteProjects: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/projects`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__projects
     * @summary Counts projects of Account.
     * @request GET:/Accounts/{id}/projects/count
     */
    accountPrototypeCountProjects: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/projects/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.prototype.__get__batches
     * @summary Queries batches of Account.
     * @request GET:/Accounts/{id}/batches
     */
    accountPrototypeGetBatches: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Batch[], any>(`/Accounts/${id}/batches${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.prototype.__create__batches
     * @summary Creates a new instance in batches of this model.
     * @request POST:/Accounts/{id}/batches
     */
    accountPrototypeCreateBatches: (id: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Accounts/${id}/batches`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.__delete__batches
     * @summary Deletes all batches of this model.
     * @request DELETE:/Accounts/{id}/batches
     */
    accountPrototypeDeleteBatches: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/batches`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.__count__batches
     * @summary Counts batches of Account.
     * @request GET:/Accounts/{id}/batches/count
     */
    accountPrototypeCountBatches: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Accounts/${id}/batches/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Account
     * @name Account.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Accounts
     */
    accountCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Accounts
     */
    accountPatchOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__put_Accounts
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Accounts
     */
    accountReplaceOrCreatePutAccounts: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Accounts
     */
    accountFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account[], any>(`/Accounts${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceOrCreate__post_Accounts_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Accounts/replaceOrCreate
     */
    accountReplaceOrCreatePostAccountsReplaceOrCreate: (data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Accounts/upsertWithWhere
     */
    accountUpsertWithWhere: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.exists__get_Accounts_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Accounts/{id}/exists
     */
    accountExistsGetAccountsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}/exists`, "GET", params),

    /**
     * @tags Account
     * @name Account.exists__head_Accounts_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Accounts/{id}
     */
    accountExistsHeadAccountsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Accounts/${id}`, "HEAD", params),

    /**
     * @tags Account
     * @name Account.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Accounts/{id}
     */
    accountFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.replaceById__put_Accounts_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Accounts/{id}
     */
    accountReplaceByIdPutAccountsId: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PUT", params, data),

    /**
     * @tags Account
     * @name Account.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Accounts/{id}
     */
    accountDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/${id}`, "DELETE", params),

    /**
     * @tags Account
     * @name Account.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Accounts/{id}
     */
    accountPrototypePatchAttributes: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}`, "PATCH", params, data),

    /**
     * @tags Account
     * @name Account.replaceById__post_Accounts_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Accounts/{id}/replace
     */
    accountReplaceByIdPostAccountsIdReplace: (id: string, data: Account, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/${id}/replace`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Accounts/findOne
     */
    accountFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Account, any>(`/Accounts/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Accounts/update
     */
    accountUpdateAll: (data: Account, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Accounts/count
     */
    accountCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Accounts/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.createChangeStream__post_Accounts_change-stream
     * @summary Create a change stream.
     * @request POST:/Accounts/change-stream
     */
    accountCreateChangeStreamPostAccountsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.createChangeStream__get_Accounts_change-stream
     * @summary Create a change stream.
     * @request GET:/Accounts/change-stream
     */
    accountCreateChangeStreamGetAccountsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Accounts/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.login
     * @summary Login a user with username/email and password.
     * @request POST:/Accounts/login
     */
    accountLogin: (credentials: object, query?: { include?: string }, params?: RequestParams) =>
      this.request<object, any>(`/Accounts/login${this.addQueryParams(query)}`, "POST", params, credentials),

    /**
     * @tags Account
     * @name Account.logout
     * @summary Logout a user with access token.
     * @request POST:/Accounts/logout
     */
    accountLogout: (params?: RequestParams) => this.request<any, any>(`/Accounts/logout`, "POST", params),

    /**
     * @tags Account
     * @name Account.prototype.verify
     * @summary Trigger user's identity verification with configured verifyOptions
     * @request POST:/Accounts/{id}/verify
     */
    accountPrototypeVerify: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/${id}/verify`, "POST", params),

    /**
     * @tags Account
     * @name Account.confirm
     * @summary Confirm a user registration with identity verification token.
     * @request GET:/Accounts/confirm
     */
    accountConfirm: (query: { uid: string; token: string; redirect?: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/confirm${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Account
     * @name Account.resetPassword
     * @summary Reset password for a user with email.
     * @request POST:/Accounts/reset
     */
    accountResetPassword: (options: object, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset`, "POST", params, options),

    /**
     * @tags Account
     * @name Account.changePassword
     * @summary Change a user's password.
     * @request POST:/Accounts/change-password
     */
    accountChangePassword: (data: { oldPassword: string; newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/change-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.setPassword
     * @summary Reset user's password via a password-reset token.
     * @request POST:/Accounts/reset-password
     */
    accountSetPassword: (data: { newPassword: string }, params?: RequestParams) =>
      this.request<any, any>(`/Accounts/reset-password`, "POST", params, data),

    /**
     * @tags Account
     * @name Account.prototype.generatePassword
     * @summary Generate new password for account
     * @request POST:/Accounts/{id}/password/generate
     */
    accountPrototypeGeneratePassword: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Accounts/${id}/password/generate`, "POST", params),
  };
  roleMappings = {
    /**
     * @tags RoleMapping
     * @name RoleMapping.prototype.__get__role
     * @summary Fetches belongsTo relation role.
     * @request GET:/RoleMappings/{id}/role
     */
    roleMappingPrototypeGetRole: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Role, any>(`/RoleMappings/${id}/role${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/RoleMappings
     */
    roleMappingCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/RoleMappings
     */
    roleMappingPatchOrCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "PATCH", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceOrCreate__put_RoleMappings
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/RoleMappings
     */
    roleMappingReplaceOrCreatePutRoleMappings: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings`, "PUT", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/RoleMappings
     */
    roleMappingFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping[], any>(`/RoleMappings${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceOrCreate__post_RoleMappings_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/RoleMappings/replaceOrCreate
     */
    roleMappingReplaceOrCreatePostRoleMappingsReplaceOrCreate: (data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/replaceOrCreate`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/RoleMappings/upsertWithWhere
     */
    roleMappingUpsertWithWhere: (data: RoleMapping, query?: { where?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(
        `/RoleMappings/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags RoleMapping
     * @name RoleMapping.exists__get_RoleMappings_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/RoleMappings/{id}/exists
     */
    roleMappingExistsGetRoleMappingsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RoleMappings/${id}/exists`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.exists__head_RoleMappings_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/RoleMappings/{id}
     */
    roleMappingExistsHeadRoleMappingsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/RoleMappings/${id}`, "HEAD", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/RoleMappings/{id}
     */
    roleMappingFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceById__put_RoleMappings_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/RoleMappings/{id}
     */
    roleMappingReplaceByIdPutRoleMappingsId: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}`, "PUT", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/RoleMappings/{id}
     */
    roleMappingDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/RoleMappings/${id}`, "DELETE", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/RoleMappings/{id}
     */
    roleMappingPrototypePatchAttributes: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}`, "PATCH", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.replaceById__post_RoleMappings_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/RoleMappings/{id}/replace
     */
    roleMappingReplaceByIdPostRoleMappingsIdReplace: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/${id}/replace`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/RoleMappings/findOne
     */
    roleMappingFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/RoleMappings/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/RoleMappings/update
     */
    roleMappingUpdateAll: (data: RoleMapping, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RoleMappings/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/RoleMappings/count
     */
    roleMappingCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/RoleMappings/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags RoleMapping
     * @name RoleMapping.createChangeStream__post_RoleMappings_change-stream
     * @summary Create a change stream.
     * @request POST:/RoleMappings/change-stream
     */
    roleMappingCreateChangeStreamPostRoleMappingsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RoleMappings/change-stream`, "POST", params, data),

    /**
     * @tags RoleMapping
     * @name RoleMapping.createChangeStream__get_RoleMappings_change-stream
     * @summary Create a change stream.
     * @request GET:/RoleMappings/change-stream
     */
    roleMappingCreateChangeStreamGetRoleMappingsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/RoleMappings/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  roles = {
    /**
     * @tags Role
     * @name Role.prototype.__findById__principals
     * @summary Find a related item by id for principals.
     * @request GET:/Roles/{id}/principals/{fk}
     */
    rolePrototypeFindByIdPrincipals: (id: string, fk: string, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals/${fk}`, "GET", params),

    /**
     * @tags Role
     * @name Role.prototype.__destroyById__principals
     * @summary Delete a related item by id for principals.
     * @request DELETE:/Roles/{id}/principals/{fk}
     */
    rolePrototypeDestroyByIdPrincipals: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Roles/${id}/principals/${fk}`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.__updateById__principals
     * @summary Update a related item by id for principals.
     * @request PUT:/Roles/{id}/principals/{fk}
     */
    rolePrototypeUpdateByIdPrincipals: (id: string, fk: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals/${fk}`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.prototype.__get__principals
     * @summary Queries principals of Role.
     * @request GET:/Roles/{id}/principals
     */
    rolePrototypeGetPrincipals: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<RoleMapping[], any>(`/Roles/${id}/principals${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.prototype.__create__principals
     * @summary Creates a new instance in principals of this model.
     * @request POST:/Roles/{id}/principals
     */
    rolePrototypeCreatePrincipals: (id: string, data: RoleMapping, params?: RequestParams) =>
      this.request<RoleMapping, any>(`/Roles/${id}/principals`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.prototype.__delete__principals
     * @summary Deletes all principals of this model.
     * @request DELETE:/Roles/{id}/principals
     */
    rolePrototypeDeletePrincipals: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Roles/${id}/principals`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.__count__principals
     * @summary Counts principals of Role.
     * @request GET:/Roles/{id}/principals/count
     */
    rolePrototypeCountPrincipals: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Roles/${id}/principals/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Role
     * @name Role.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Roles
     */
    roleCreate: (data: Role, params?: RequestParams) => this.request<Role, any>(`/Roles`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Roles
     */
    rolePatchOrCreate: (data: Role, params?: RequestParams) => this.request<Role, any>(`/Roles`, "PATCH", params, data),

    /**
     * @tags Role
     * @name Role.replaceOrCreate__put_Roles
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Roles
     */
    roleReplaceOrCreatePutRoles: (data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Roles
     */
    roleFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role[], any>(`/Roles${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.replaceOrCreate__post_Roles_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Roles/replaceOrCreate
     */
    roleReplaceOrCreatePostRolesReplaceOrCreate: (data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Roles/upsertWithWhere
     */
    roleUpsertWithWhere: (data: Role, query?: { where?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.exists__get_Roles_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Roles/{id}/exists
     */
    roleExistsGetRolesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Roles/${id}/exists`, "GET", params),

    /**
     * @tags Role
     * @name Role.exists__head_Roles_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Roles/{id}
     */
    roleExistsHeadRolesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Roles/${id}`, "HEAD", params),

    /**
     * @tags Role
     * @name Role.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Roles/{id}
     */
    roleFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.replaceById__put_Roles_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Roles/{id}
     */
    roleReplaceByIdPutRolesId: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}`, "PUT", params, data),

    /**
     * @tags Role
     * @name Role.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Roles/{id}
     */
    roleDeleteById: (id: string, params?: RequestParams) => this.request<object, any>(`/Roles/${id}`, "DELETE", params),

    /**
     * @tags Role
     * @name Role.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Roles/{id}
     */
    rolePrototypePatchAttributes: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}`, "PATCH", params, data),

    /**
     * @tags Role
     * @name Role.replaceById__post_Roles_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Roles/{id}/replace
     */
    roleReplaceByIdPostRolesIdReplace: (id: string, data: Role, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/${id}/replace`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Roles/findOne
     */
    roleFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Role, any>(`/Roles/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Roles/update
     */
    roleUpdateAll: (data: Role, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Roles/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Roles/count
     */
    roleCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Roles/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Role
     * @name Role.createChangeStream__post_Roles_change-stream
     * @summary Create a change stream.
     * @request POST:/Roles/change-stream
     */
    roleCreateChangeStreamPostRolesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Roles/change-stream`, "POST", params, data),

    /**
     * @tags Role
     * @name Role.createChangeStream__get_Roles_change-stream
     * @summary Create a change stream.
     * @request GET:/Roles/change-stream
     */
    roleCreateChangeStreamGetRolesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Roles/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  accountTokens = {
    /**
     * @tags AccountToken
     * @name AccountToken.prototype.__get__user
     * @summary Fetches belongsTo relation user.
     * @request GET:/AccountTokens/{id}/user
     */
    accountTokenPrototypeGetUser: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/AccountTokens/${id}/user${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/AccountTokens
     */
    accountTokenCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/AccountTokens
     */
    accountTokenPatchOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__put_AccountTokens
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/AccountTokens
     */
    accountTokenReplaceOrCreatePutAccountTokens: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/AccountTokens
     */
    accountTokenFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken[], any>(`/AccountTokens${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceOrCreate__post_AccountTokens_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/AccountTokens/replaceOrCreate
     */
    accountTokenReplaceOrCreatePostAccountTokensReplaceOrCreate: (data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/replaceOrCreate`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/AccountTokens/upsertWithWhere
     */
    accountTokenUpsertWithWhere: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(
        `/AccountTokens/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__get_AccountTokens_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/AccountTokens/{id}/exists
     */
    accountTokenExistsGetAccountTokensIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}/exists`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.exists__head_AccountTokens_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/AccountTokens/{id}
     */
    accountTokenExistsHeadAccountTokensId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/AccountTokens/${id}`, "HEAD", params),

    /**
     * @tags AccountToken
     * @name AccountToken.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/AccountTokens/{id}
     */
    accountTokenFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__put_AccountTokens_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/AccountTokens/{id}
     */
    accountTokenReplaceByIdPutAccountTokensId: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PUT", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/AccountTokens/{id}
     */
    accountTokenDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/AccountTokens/${id}`, "DELETE", params),

    /**
     * @tags AccountToken
     * @name AccountToken.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/AccountTokens/{id}
     */
    accountTokenPrototypePatchAttributes: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}`, "PATCH", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.replaceById__post_AccountTokens_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/AccountTokens/{id}/replace
     */
    accountTokenReplaceByIdPostAccountTokensIdReplace: (id: string, data: AccountToken, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/${id}/replace`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/AccountTokens/findOne
     */
    accountTokenFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<AccountToken, any>(`/AccountTokens/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/AccountTokens/update
     */
    accountTokenUpdateAll: (data: AccountToken, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/AccountTokens/count
     */
    accountTokenCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/AccountTokens/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__post_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request POST:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamPostAccountTokensChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/AccountTokens/change-stream`, "POST", params, data),

    /**
     * @tags AccountToken
     * @name AccountToken.createChangeStream__get_AccountTokens_change-stream
     * @summary Create a change stream.
     * @request GET:/AccountTokens/change-stream
     */
    accountTokenCreateChangeStreamGetAccountTokensChangeStream: (
      query?: { options?: string },
      params?: RequestParams,
    ) => this.request<File, any>(`/AccountTokens/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  containers = {
    /**
     * @tags Container
     * @name Container.getContainers
     * @request GET:/Containers
     */
    containerGetContainers: (params?: RequestParams) => this.request<XAny[], any>(`/Containers`, "GET", params),

    /**
     * @tags Container
     * @name Container.createContainer
     * @request POST:/Containers
     */
    containerCreateContainer: (options: object, params?: RequestParams) =>
      this.request<object, any>(`/Containers`, "POST", params, options),

    /**
     * @tags Container
     * @name Container.destroyContainer
     * @request DELETE:/Containers/{container}
     */
    containerDestroyContainer: (container: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.getContainer
     * @request GET:/Containers/{container}
     */
    containerGetContainer: (container: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFiles
     * @request GET:/Containers/{container}/files
     */
    containerGetFiles: (container: string, params?: RequestParams) =>
      this.request<XAny[], any>(`/Containers/${container}/files`, "GET", params),

    /**
     * @tags Container
     * @name Container.getFile
     * @request GET:/Containers/{container}/files/{file}
     */
    containerGetFile: (container: string, file: string, params?: RequestParams) =>
      this.request<object, any>(`/Containers/${container}/files/${file}`, "GET", params),

    /**
     * @tags Container
     * @name Container.removeFile
     * @request DELETE:/Containers/{container}/files/{file}
     */
    containerRemoveFile: (container: string, file: string, params?: RequestParams) =>
      this.request<{ undefined?: object }, any>(`/Containers/${container}/files/${file}`, "DELETE", params),

    /**
     * @tags Container
     * @name Container.upload
     * @request POST:/Containers/{container}/upload
     */
    containerUpload: (container: string, params?: RequestParams) =>
      this.request<{ result?: object }, any>(`/Containers/${container}/upload`, "POST", params),

    /**
     * @tags Container
     * @name Container.download
     * @request GET:/Containers/{container}/download/{file}
     */
    containerDownload: (container: string, file: string, params?: RequestParams) =>
      this.request<any, any>(`/Containers/${container}/download/${file}`, "GET", params),
  };
  projects = {
    /**
     * @tags Project
     * @name Project.prototype.__get__account
     * @summary Fetches belongsTo relation account.
     * @request GET:/Projects/{id}/account
     */
    projectPrototypeGetAccount: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Projects/${id}/account${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.__findById__trackRecords
     * @summary Find a related item by id for trackRecords.
     * @request GET:/Projects/{id}/trackRecords/{fk}
     */
    projectPrototypeFindByIdTrackRecords: (id: string, fk: string, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Projects/${id}/trackRecords/${fk}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.__destroyById__trackRecords
     * @summary Delete a related item by id for trackRecords.
     * @request DELETE:/Projects/{id}/trackRecords/{fk}
     */
    projectPrototypeDestroyByIdTrackRecords: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Projects/${id}/trackRecords/${fk}`, "DELETE", params),

    /**
     * @tags Project
     * @name Project.prototype.__updateById__trackRecords
     * @summary Update a related item by id for trackRecords.
     * @request PUT:/Projects/{id}/trackRecords/{fk}
     */
    projectPrototypeUpdateByIdTrackRecords: (id: string, fk: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Projects/${id}/trackRecords/${fk}`, "PUT", params, data),

    /**
     * @tags Project
     * @name Project.prototype.__findById__batches
     * @summary Find a related item by id for batches.
     * @request GET:/Projects/{id}/batches/{fk}
     */
    projectPrototypeFindByIdBatches: (id: string, fk: string, params?: RequestParams) =>
      this.request<Batch, any>(`/Projects/${id}/batches/${fk}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.__destroyById__batches
     * @summary Delete a related item by id for batches.
     * @request DELETE:/Projects/{id}/batches/{fk}
     */
    projectPrototypeDestroyByIdBatches: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Projects/${id}/batches/${fk}`, "DELETE", params),

    /**
     * @tags Project
     * @name Project.prototype.__updateById__batches
     * @summary Update a related item by id for batches.
     * @request PUT:/Projects/{id}/batches/{fk}
     */
    projectPrototypeUpdateByIdBatches: (id: string, fk: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Projects/${id}/batches/${fk}`, "PUT", params, data),

    /**
     * @tags Project
     * @name Project.prototype.__get__trackRecords
     * @summary Queries trackRecords of Project.
     * @request GET:/Projects/{id}/trackRecords
     */
    projectPrototypeGetTrackRecords: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<TrackRecord[], any>(`/Projects/${id}/trackRecords${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.__create__trackRecords
     * @summary Creates a new instance in trackRecords of this model.
     * @request POST:/Projects/{id}/trackRecords
     */
    projectPrototypeCreateTrackRecords: (id: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Projects/${id}/trackRecords`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.prototype.__delete__trackRecords
     * @summary Deletes all trackRecords of this model.
     * @request DELETE:/Projects/{id}/trackRecords
     */
    projectPrototypeDeleteTrackRecords: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Projects/${id}/trackRecords`, "DELETE", params),

    /**
     * @tags Project
     * @name Project.prototype.__count__trackRecords
     * @summary Counts trackRecords of Project.
     * @request GET:/Projects/{id}/trackRecords/count
     */
    projectPrototypeCountTrackRecords: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Projects/${id}/trackRecords/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Project
     * @name Project.prototype.__get__batches
     * @summary Queries batches of Project.
     * @request GET:/Projects/{id}/batches
     */
    projectPrototypeGetBatches: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Batch[], any>(`/Projects/${id}/batches${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.__create__batches
     * @summary Creates a new instance in batches of this model.
     * @request POST:/Projects/{id}/batches
     */
    projectPrototypeCreateBatches: (id: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Projects/${id}/batches`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.prototype.__delete__batches
     * @summary Deletes all batches of this model.
     * @request DELETE:/Projects/{id}/batches
     */
    projectPrototypeDeleteBatches: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Projects/${id}/batches`, "DELETE", params),

    /**
     * @tags Project
     * @name Project.prototype.__count__batches
     * @summary Counts batches of Project.
     * @request GET:/Projects/{id}/batches/count
     */
    projectPrototypeCountBatches: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Projects/${id}/batches/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Project
     * @name Project.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Projects
     */
    projectCreate: (data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Projects
     */
    projectPatchOrCreate: (data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects`, "PATCH", params, data),

    /**
     * @tags Project
     * @name Project.replaceOrCreate__put_Projects
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Projects
     */
    projectReplaceOrCreatePutProjects: (data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects`, "PUT", params, data),

    /**
     * @tags Project
     * @name Project.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Projects
     */
    projectFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Project[], any>(`/Projects${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.replaceOrCreate__post_Projects_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Projects/replaceOrCreate
     */
    projectReplaceOrCreatePostProjectsReplaceOrCreate: (data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Projects/upsertWithWhere
     */
    projectUpsertWithWhere: (data: Project, query?: { where?: string }, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.exists__get_Projects_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Projects/{id}/exists
     */
    projectExistsGetProjectsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Projects/${id}/exists`, "GET", params),

    /**
     * @tags Project
     * @name Project.exists__head_Projects_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Projects/{id}
     */
    projectExistsHeadProjectsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Projects/${id}`, "HEAD", params),

    /**
     * @tags Project
     * @name Project.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Projects/{id}
     */
    projectFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.replaceById__put_Projects_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Projects/{id}
     */
    projectReplaceByIdPutProjectsId: (id: string, data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/${id}`, "PUT", params, data),

    /**
     * @tags Project
     * @name Project.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Projects/{id}
     */
    projectDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Projects/${id}`, "DELETE", params),

    /**
     * @tags Project
     * @name Project.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Projects/{id}
     */
    projectPrototypePatchAttributes: (id: string, data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/${id}`, "PATCH", params, data),

    /**
     * @tags Project
     * @name Project.replaceById__post_Projects_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Projects/{id}/replace
     */
    projectReplaceByIdPostProjectsIdReplace: (id: string, data: Project, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/${id}/replace`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Projects/findOne
     */
    projectFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Project, any>(`/Projects/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Projects/update
     */
    projectUpdateAll: (data: Project, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Projects/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Projects/count
     */
    projectCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Projects/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.createChangeStream__post_Projects_change-stream
     * @summary Create a change stream.
     * @request POST:/Projects/change-stream
     */
    projectCreateChangeStreamPostProjectsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Projects/change-stream`, "POST", params, data),

    /**
     * @tags Project
     * @name Project.createChangeStream__get_Projects_change-stream
     * @summary Create a change stream.
     * @request GET:/Projects/change-stream
     */
    projectCreateChangeStreamGetProjectsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Projects/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Project
     * @name Project.prototype.download
     * @summary Transfer data from file to database
     * @request GET:/Projects/{id}/download
     */
    projectPrototypeDownload: (
      id: string,
      query: { key: string; fileType: string; filter: string },
      params?: RequestParams,
    ) => this.request<XAny, any>(`/Projects/${id}/download${this.addQueryParams(query)}`, "GET", params),
  };
  trackRecords = {
    /**
     * @tags TrackRecord
     * @name TrackRecord.prototype.__get__project
     * @summary Fetches belongsTo relation project.
     * @request GET:/TrackRecords/{id}/project
     */
    trackRecordPrototypeGetProject: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Project, any>(`/TrackRecords/${id}/project${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.prototype.__get__batch
     * @summary Fetches belongsTo relation batch.
     * @request GET:/TrackRecords/{id}/batch
     */
    trackRecordPrototypeGetBatch: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Batch, any>(`/TrackRecords/${id}/batch${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/TrackRecords
     */
    trackRecordCreate: (data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords`, "POST", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/TrackRecords
     */
    trackRecordPatchOrCreate: (data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords`, "PATCH", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.replaceOrCreate__put_TrackRecords
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/TrackRecords
     */
    trackRecordReplaceOrCreatePutTrackRecords: (data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords`, "PUT", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/TrackRecords
     */
    trackRecordFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<TrackRecord[], any>(`/TrackRecords${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.replaceOrCreate__post_TrackRecords_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/TrackRecords/replaceOrCreate
     */
    trackRecordReplaceOrCreatePostTrackRecordsReplaceOrCreate: (data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/replaceOrCreate`, "POST", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/TrackRecords/upsertWithWhere
     */
    trackRecordUpsertWithWhere: (data: TrackRecord, query?: { where?: string }, params?: RequestParams) =>
      this.request<TrackRecord, any>(
        `/TrackRecords/upsertWithWhere${this.addQueryParams(query)}`,
        "POST",
        params,
        data,
      ),

    /**
     * @tags TrackRecord
     * @name TrackRecord.exists__get_TrackRecords_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/TrackRecords/{id}/exists
     */
    trackRecordExistsGetTrackRecordsIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/TrackRecords/${id}/exists`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.exists__head_TrackRecords_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/TrackRecords/{id}
     */
    trackRecordExistsHeadTrackRecordsId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/TrackRecords/${id}`, "HEAD", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/TrackRecords/{id}
     */
    trackRecordFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.replaceById__put_TrackRecords_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/TrackRecords/{id}
     */
    trackRecordReplaceByIdPutTrackRecordsId: (id: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/${id}`, "PUT", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/TrackRecords/{id}
     */
    trackRecordDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/TrackRecords/${id}`, "DELETE", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/TrackRecords/{id}
     */
    trackRecordPrototypePatchAttributes: (id: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/${id}`, "PATCH", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.replaceById__post_TrackRecords_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/TrackRecords/{id}/replace
     */
    trackRecordReplaceByIdPostTrackRecordsIdReplace: (id: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/${id}/replace`, "POST", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/TrackRecords/findOne
     */
    trackRecordFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/TrackRecords/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/TrackRecords/update
     */
    trackRecordUpdateAll: (data: TrackRecord, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/TrackRecords/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/TrackRecords/count
     */
    trackRecordCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/TrackRecords/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags TrackRecord
     * @name TrackRecord.createChangeStream__post_TrackRecords_change-stream
     * @summary Create a change stream.
     * @request POST:/TrackRecords/change-stream
     */
    trackRecordCreateChangeStreamPostTrackRecordsChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/TrackRecords/change-stream`, "POST", params, data),

    /**
     * @tags TrackRecord
     * @name TrackRecord.createChangeStream__get_TrackRecords_change-stream
     * @summary Create a change stream.
     * @request GET:/TrackRecords/change-stream
     */
    trackRecordCreateChangeStreamGetTrackRecordsChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/TrackRecords/change-stream${this.addQueryParams(query)}`, "GET", params),
  };
  batches = {
    /**
     * @tags Batch
     * @name Batch.prototype.__get__project
     * @summary Fetches belongsTo relation project.
     * @request GET:/Batches/{id}/project
     */
    batchPrototypeGetProject: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Project, any>(`/Batches/${id}/project${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__get__uploader
     * @summary Fetches belongsTo relation uploader.
     * @request GET:/Batches/{id}/uploader
     */
    batchPrototypeGetUploader: (id: string, query?: { refresh?: boolean }, params?: RequestParams) =>
      this.request<Account, any>(`/Batches/${id}/uploader${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__findById__trackRecords
     * @summary Find a related item by id for trackRecords.
     * @request GET:/Batches/{id}/trackRecords/{fk}
     */
    batchPrototypeFindByIdTrackRecords: (id: string, fk: string, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Batches/${id}/trackRecords/${fk}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__destroyById__trackRecords
     * @summary Delete a related item by id for trackRecords.
     * @request DELETE:/Batches/{id}/trackRecords/{fk}
     */
    batchPrototypeDestroyByIdTrackRecords: (id: string, fk: string, params?: RequestParams) =>
      this.request<any, any>(`/Batches/${id}/trackRecords/${fk}`, "DELETE", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__updateById__trackRecords
     * @summary Update a related item by id for trackRecords.
     * @request PUT:/Batches/{id}/trackRecords/{fk}
     */
    batchPrototypeUpdateByIdTrackRecords: (id: string, fk: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Batches/${id}/trackRecords/${fk}`, "PUT", params, data),

    /**
     * @tags Batch
     * @name Batch.prototype.__get__trackRecords
     * @summary Queries trackRecords of Batch.
     * @request GET:/Batches/{id}/trackRecords
     */
    batchPrototypeGetTrackRecords: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<TrackRecord[], any>(`/Batches/${id}/trackRecords${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__create__trackRecords
     * @summary Creates a new instance in trackRecords of this model.
     * @request POST:/Batches/{id}/trackRecords
     */
    batchPrototypeCreateTrackRecords: (id: string, data: TrackRecord, params?: RequestParams) =>
      this.request<TrackRecord, any>(`/Batches/${id}/trackRecords`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.prototype.__delete__trackRecords
     * @summary Deletes all trackRecords of this model.
     * @request DELETE:/Batches/{id}/trackRecords
     */
    batchPrototypeDeleteTrackRecords: (id: string, params?: RequestParams) =>
      this.request<any, any>(`/Batches/${id}/trackRecords`, "DELETE", params),

    /**
     * @tags Batch
     * @name Batch.prototype.__count__trackRecords
     * @summary Counts trackRecords of Batch.
     * @request GET:/Batches/{id}/trackRecords/count
     */
    batchPrototypeCountTrackRecords: (id: string, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(
        `/Batches/${id}/trackRecords/count${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags Batch
     * @name Batch.create
     * @summary Create a new instance of the model and persist it into the data source.
     * @request POST:/Batches
     */
    batchCreate: (data: Batch, params?: RequestParams) => this.request<Batch, any>(`/Batches`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.patchOrCreate
     * @summary Patch an existing model instance or insert a new one into the data source.
     * @request PATCH:/Batches
     */
    batchPatchOrCreate: (data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches`, "PATCH", params, data),

    /**
     * @tags Batch
     * @name Batch.replaceOrCreate__put_Batches
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request PUT:/Batches
     */
    batchReplaceOrCreatePutBatches: (data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches`, "PUT", params, data),

    /**
     * @tags Batch
     * @name Batch.find
     * @summary Find all instances of the model matched by filter from the data source.
     * @request GET:/Batches
     */
    batchFind: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Batch[], any>(`/Batches${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.replaceOrCreate__post_Batches_replaceOrCreate
     * @summary Replace an existing model instance or insert a new one into the data source.
     * @request POST:/Batches/replaceOrCreate
     */
    batchReplaceOrCreatePostBatchesReplaceOrCreate: (data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/replaceOrCreate`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.upsertWithWhere
     * @summary Update an existing model instance or insert a new one into the data source based on the where criteria.
     * @request POST:/Batches/upsertWithWhere
     */
    batchUpsertWithWhere: (data: Batch, query?: { where?: string }, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/upsertWithWhere${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.exists__get_Batches_{id}_exists
     * @summary Check whether a model instance exists in the data source.
     * @request GET:/Batches/{id}/exists
     */
    batchExistsGetBatchesIdExists: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Batches/${id}/exists`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.exists__head_Batches_{id}
     * @summary Check whether a model instance exists in the data source.
     * @request HEAD:/Batches/{id}
     */
    batchExistsHeadBatchesId: (id: string, params?: RequestParams) =>
      this.request<{ exists?: boolean }, any>(`/Batches/${id}`, "HEAD", params),

    /**
     * @tags Batch
     * @name Batch.findById
     * @summary Find a model instance by {{id}} from the data source.
     * @request GET:/Batches/{id}
     */
    batchFindById: (id: string, query?: { filter?: string }, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/${id}${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.replaceById__put_Batches_{id}
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request PUT:/Batches/{id}
     */
    batchReplaceByIdPutBatchesId: (id: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/${id}`, "PUT", params, data),

    /**
     * @tags Batch
     * @name Batch.deleteById
     * @summary Delete a model instance by {{id}} from the data source.
     * @request DELETE:/Batches/{id}
     */
    batchDeleteById: (id: string, params?: RequestParams) =>
      this.request<object, any>(`/Batches/${id}`, "DELETE", params),

    /**
     * @tags Batch
     * @name Batch.prototype.patchAttributes
     * @summary Patch attributes for a model instance and persist it into the data source.
     * @request PATCH:/Batches/{id}
     */
    batchPrototypePatchAttributes: (id: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/${id}`, "PATCH", params, data),

    /**
     * @tags Batch
     * @name Batch.replaceById__post_Batches_{id}_replace
     * @summary Replace attributes for a model instance and persist it into the data source.
     * @request POST:/Batches/{id}/replace
     */
    batchReplaceByIdPostBatchesIdReplace: (id: string, data: Batch, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/${id}/replace`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.findOne
     * @summary Find first instance of the model matched by filter from the data source.
     * @request GET:/Batches/findOne
     */
    batchFindOne: (query?: { filter?: string }, params?: RequestParams) =>
      this.request<Batch, any>(`/Batches/findOne${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.updateAll
     * @summary Update instances of the model matched by {{where}} from the data source.
     * @request POST:/Batches/update
     */
    batchUpdateAll: (data: Batch, query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Batches/update${this.addQueryParams(query)}`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.count
     * @summary Count instances of the model matched by where from the data source.
     * @request GET:/Batches/count
     */
    batchCount: (query?: { where?: string }, params?: RequestParams) =>
      this.request<{ count?: number }, any>(`/Batches/count${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.createChangeStream__post_Batches_change-stream
     * @summary Create a change stream.
     * @request POST:/Batches/change-stream
     */
    batchCreateChangeStreamPostBatchesChangeStream: (data: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Batches/change-stream`, "POST", params, data),

    /**
     * @tags Batch
     * @name Batch.createChangeStream__get_Batches_change-stream
     * @summary Create a change stream.
     * @request GET:/Batches/change-stream
     */
    batchCreateChangeStreamGetBatchesChangeStream: (query?: { options?: string }, params?: RequestParams) =>
      this.request<File, any>(`/Batches/change-stream${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags Batch
     * @name Batch.prototype.transfer
     * @summary Transfer data from file to database
     * @request POST:/Batches/{id}/transfer
     */
    batchPrototypeTransfer: (id: string, params?: RequestParams) =>
      this.request<boolean, any>(`/Batches/${id}/transfer`, "POST", params),
  };
}
