import React, {
  useCallback,
  Fragment,
  useMemo,
  useState,
  useEffect,
} from "react";
import "./csv.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import PublishIcon from "@material-ui/icons/Publish";
import * as PapaParse from "papaparse";
import { ParseResult, Parser, ParseError } from "papaparse";
import { RequestParams, Batch } from "../../react-admin-loopback/api/api";
import { API_URL } from "../../constant";
import fetchJson from "../../react-admin-loopback/fetch";
import { get } from "lodash";
import { useRedirect } from "react-admin";
const useStyleMain = makeStyles((theme) => ({
  main: {
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: 18,
    padding: 20,
    // paddingBottom : 90,
    height: "calc(100vh)",
  },
}));
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const ImportCsv = (props) => {
  const [dataProjects, setDataProjects] = useState(Array());
  const [data, setData] = useState(Array());
  const [projectSelect, setProjectSelect] = useState();
  const [header, setHeader] = useState(Array());
  const [file, setFile] = useState("");
  const [selectFile, setSelectFile] = useState(false);
  const classes = useStyles();
  const API = new LoopbackAPI();
  const [columns, setColumns] = useState(Array());
  const redirect = useRedirect();
  const [showUpload, setShowUpload] = useState(false);
  const classMain = useStyleMain();

  const onFocus = useCallback(async () => {
    // if (dataProjects.length > 0) return;
    await API.projects
      .projectFind()
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          setDataProjects(result.data);
          setHeader(get(result,'data[0].headers'))
          setProjectSelect(get(result,'data[0]'))
        }
      })
      .catch();
  }, [API.projects]);

  useEffect(()=>{
    onFocus();
  },[])

  

  // const onInputChange = useCallback(
  //   (params, value) => {
  //     if (!!dataProjects.map((item) => item.name).includes(value)) {
  //       const data = dataProjects.filter((item) => item.name === value)[0];
  //       setSelectFile(true);
  //       setHeader(data.headers);
  //       setProjectSelect(data);
  //     }
  //   },
  //   [dataProjects]
  // );

  const projectRedirect = (props: any) => <Link to="/CSV" {...props} />;

  const handleForce = useCallback(
    (params) => {
      if (params.length > 0) {
        const listData = params.map((item, index) => {
          if (!!Object.keys(item).includes("id")) {
            return item;
          }
          return {
            ...item,
            id: index + 1,
          };
        });
        if (listData.length > 30) {
          setData(listData.slice(0, 30));
        } else {
          setData(listData);
        }
        const columns = header.map((i, index) => {
          return {
            field: i.columnName,
            headerName: i.columnName,
            width: 200,
          };
        });

        columns.unshift({
          field: "id",
          headerName: "Id",
          width: 100,
        });
        setColumns(columns);
      }
    },
    [header]
  );

  // const onChange = useCallback((event, value, reason) => {
  //   if (reason === "clear") {
  //     setSelectFile(false);
  //   }
  // }, []);

  const handleUpload = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", file);
    await fetchJson(`${API_URL}/Containers/csv/upload`, {
      method: "POST",
      body: formData,
    }).then(async (result) => {
      const dataBatch = {
        originalFile: get(result, "json.result.files.file[0].name"),
        projectId: get(projectSelect, "id"),
      };
      await API.batches.batchCreate(dataBatch as Batch).then(async (data) => {
        await API.batches
          .batchPrototypeTransfer(get(data, "data.id"))
          .then(() => {
            redirect("/CSV");
          });
      });
    });
  }, [API.batches, file, projectSelect, redirect]);

  const selectScv = useCallback(
    (event) => {
      setShowUpload(true);
      setFile(event.target.files[0]);
      const array = Array();
      PapaParse.parse(event.target.files[0], {
        delimiter: "", // auto-detect
        newline: "", // auto-detect
        quoteChar: '"',
        escapeChar: "\\", // TODO: Test this
        header: true,
        trimHeaders: true,
        step<T>(results: ParseResult<T>, parser: Parser) {
          array.push(results.data as any);
        },
        complete<T>(results: ParseResult<T>, file?: File) {
          handleForce(array);
        },
        error(error: ParseError, file?: File): void {
          console.log(error); // TODO: Error file reading
        },
        skipEmptyLines: "greedy",
      });
    },
    [handleForce]
  );

  return (
    <Fragment>
      {useMemo(
        () => (
          <div className={classMain.main}>
            <div className="main-import">
              <div className="row">
                <Button component={projectRedirect}>
                  <ArrowBackIcon />
                </Button>
                <h3>CSV</h3>
              </div>
              <div className="header">
                {/* <Autocomplete
                  id="country-select-demo"
                  style={{
                    width: 250,
                    marginTop: 20,
                    background: "rgba(0, 0, 0, 0.04)",
                  }}
                  options={dataProjects.map((item) => item.name)}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  renderOption={(option) => (
                    <React.Fragment>{option}</React.Fragment>
                  )}
                  onInputChange={onInputChange}
                  onChange={onChange}
                  onFocus={onFocus}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Select Project"
                      placeholder="Search Project"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> */}
                <div />

                {!!showUpload && (
                  <Button
                    variant="contained"
                    startIcon={<PublishIcon />}
                    style={{
                      background: "transparent",
                      boxShadow: "none",
                      textTransform: "none",
                      marginRight: 20,
                      marginTop: 20,
                      fontWeight: 500,
                      maxWidth: 100,
                      color: "#3f51b5",
                    }}
                    onClick={handleUpload}
                  >
                    UPLOAD
                  </Button>
                )}
              </div>

              <div style={{ marginTop: 20, marginBottom: 10 }}>
                <input accept=".csv" type="file" onChange={selectScv} />
              </div>
              <DataGrid rows={data} columns={columns} checkboxSelection />
            </div>
          </div>
        ),
        [classMain.main, columns, data, handleUpload, selectScv, showUpload]
      )}
    </Fragment>
  );
};
