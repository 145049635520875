import React, { useCallback, useState, Fragment, useMemo } from "react";
import { useTranslate, useRedirect } from "react-admin";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { storage } from "../../react-admin-loopback";
import SaveIcon from "@material-ui/icons/Save";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import { Project } from "../../react-admin-loopback/api/api";
import { EditProject } from "./EditProject";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  main: {
    boxShadow : "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    borderRadius : 18,
    padding : 20
  },
}));
export const CreateProject = (props) => {
  const translate = useTranslate();
  const [data, setData] = useState(Array());
  const [text, setText] = useState("");
  const projectRedirect = (props: any) => <Link to="/Projects" {...props} />;
  const redirect = useRedirect();
  const API = new LoopbackAPI();
  const classes = useStyles();

  const onSave = useCallback(async () => {
    const accountId = storage.load("user").id;
    if (text !== "") {
      const newProject = {
        name: text,
        headers: data,
        accountId: accountId,
      };

      await API.projects
        .projectCreate(newProject as Project)
        .then((result) => {
          redirect("/Projects");
        })
        .catch((err) => {});
    }
  }, [API.projects, data, redirect, text]);

  const callback = useCallback((param) => {
    setData(param);
  }, []);

  const onChangeText = useCallback((event) => {
    setText(event.target.value);
  }, []);

  return (
    <Fragment>
      {useMemo(
        () => (
          <div className={classes.main}>
            <div className="row">
              <Button component={projectRedirect}>
                <ArrowBackIcon />
              </Button>
              <h3>{translate("back-office.projects")}</h3>
            </div>
            <div id="showDetailAccount" className="row">
              <div className="col-10 left">
                <div className="row">
                  <div className="col-6">
                    {" "}
                    <TextField label="name" onChange={onChangeText} />
                  </div>
                </div>
                <span style={{ marginTop: 20, marginBottom: 30 }}>Headers</span>
                <EditProject
                  callback={callback}
                  record={null}
                  headers={data}
                  type="createProject"
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              style={{
                width: 100,
              }}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        ),
        [callback, classes.main, data, onChangeText, onSave, translate]
      )}
    </Fragment>
  );
};
