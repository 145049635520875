import storage from "./storage";
import { LoopbackAPI } from "./api/loopback-api";
import { API_URL } from "../constant";
export default {
  login: ({ email, password }) => {
    const request = new Request(`${API_URL}/Accounts/login`, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(async ({ ttl, ...data }) => {
        const query = {
          filter: '{"include": {"relation": "roles"}}',
        };
        try {
          const API = new LoopbackAPI();
          const account: any = await API.accounts.accountFindById(
            data.userId,
            query,
            {
              headers: {
                authorization: data.id,
                "Content-Type": "application/json",
              },
            }
          );

          if (account.data) {
            const dataUser = account.data;
            const indexRoles = dataUser.roles.findIndex(
              (roles) =>
                roles.name === "SUPERADMIN" ||
                roles.name === "ADMIN" ||
                roles.name === "USER"
            );
            if (indexRoles >= 0 && !!dataUser.isActive) {
              storage.save("lbtoken", data, ttl);
              storage.save("user", dataUser, ttl);
              storage.save("roles", dataUser.roles[0].name, ttl);
            } else {
              throw new Error(
                "Ce compte n'est pas autorisé à accéder à la page"
              );
            }
          }
        } catch (e) {
          throw new Error("Ce compte n'est pas autorisé à accéder à la page");
        }
      });
  },
  logout: () => {
    storage.remove("lbtoken");
    return Promise.resolve();
  },
  checkError: (error) => {
    // ...
  },
  checkAuth: () => {
    const token = storage.load("lbtoken");
    if (token && token.id) {
      return Promise.resolve();
    } else {
      storage.remove("lbtoken");
      return Promise.reject({ redirectTo: "/login" });
    }
  },
  getPermissions: () => {
    const roles = storage.load("roles");
    // return Promise.resolve(true)
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};
