import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { useSetLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={"Setting"}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        {/* <ConfigurationMenu /> */}
    </UserMenu>
);

const CustomAppBar = (props: any) => {    
 
    
    const classes = useStyles();
    const [lang, setLang] = React.useState("en");
    const setLocale = useSetLocale();
    return (
        <AppBar color="default" {...props} userMenu={<CustomUserMenu />}>
           <b>Fabien CSV</b>
            <span className={classes.spacer} />
            <select className="select-lang m-1" value={lang} onChange={(e)=>{
                setLang(e.target.value as any) ;
                setLocale(e.target.value)
            }}>
                <option value="en">English</option>
                <option value="fr">Français</option>
                <option value="de">Deutsch</option>
            </select>
        </AppBar>
    );
};

export default CustomAppBar;
