import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  "form": {
    "email": "Email",
    "emailInvalid": "Invalid email",
    "emailExisted": "This email already exists",
    "sentEmail": "Please enter your email address to receive instructions for resetting the password",
    "newPassword": "New password",
    "oldPassword": "Old password",
    "password": "Password",
    "changePassword": "Change password",
    "confirmPassword": "Confirm password",
    "passwordInvalid": "Invalid password",
    "atLeast": "Password must contain at least 4 characters",
    "passwordNotMatch": "Passwords do not match!",
    "username": "Username",
    "usernameRequired": "Username required",
    "name": "Name",
    "nameRequired": "Name required",
    "birthday": "Birthday",
    "birthdayRequired": "Birthday required",
    "telephone": "Phone number",
    "telephoneRequired": "Phone number required",
    "telephoneInvalid": "Invalid phone number",
    "firstName": "First name",
    "firstNameRequired": "First name required",
    "lastName": "Last name",
    "lastNameRequired": "Last name required",
    "sex": "Sex",
    "sexRequired": "Sex required",
    "address": "Address",
    "cityResidence": "City of residence",
    "city": "City",
    "addressRequired": "Address required",
    "fieldRequired": "This field is required!",
    "accept": "Accept",
    "addPhoto": "Add profile photo",
    "buttonSendEmail": "Send",
    "verify": "Verify code",
    "enterCode": "Enter verification code",
    "brand": "Brand",
    "country": "Country",
    "date": "Date",
    "number": "Number",
    "nameUp": "Name",
    "firstNameUp": "First name",
    "male": "Male",
    "female": "Female",
    "readTerms": "I have read and undertake the Terms and Conditions",
    "destination": "Arrival",
    "depart": "Departure",
    "time": "Hour",
    "place": "Number of places",
    "invalidLocation": "Invalid location",
    "price": "Price",
    "message": "Your message",
    "selectOne": "Select one"
  },
  "button": {
    "submit": "Submit",
    "back": "Back",
    "choose": "Choose",
    "chooseTransport": "Select",
    "pay": "Pay",
    "ok": "OK",
    "modifier": "Modify",
    "view": "View",
    "remove": "Remove",
    "modify": "Modify",
    "send": "Send",
    "validate": "Validate",
    "continue": "Continue",
    "register": "Register",
    "cancel": "Cancel",
    "cancelTrip": "Cancel this trip",
    "book": "Reserve",
    "note": "Note",
    "modifyProfile": "Edit my profile",
    "changePassword": "Change password",
    "contact": "Contact",
    "confirm": "Confirm",
    "signal": "Report",
    "makeProposed": "Make an offer",
    "acceptAndPay": "Accept and pay",
    "response": "Response",
    "return": "Return",
    "next": "Next",
    "delete": "Delete",
    "done": "OK",
    "delivering": "In the course of delivery",
    "delivered": "Delivered",
    "sendAgain": "Send again",
    "allStatus": "All status",
    "refused": "Refused",
    "accept": "Accepted",
    "make-an-offer": "Make an offer",
    "save": "Save",
    "sender": "Sender",
    "deliveryMan": "Delivery Man"
  },
  "message": {
    "success": "Success!",
    "error": "Error!",
    "warning": "Warning!",
    "comingSoon": "Coming soon!",
    "pleaseWait": "Please wait!",
    "emailSent": "Email sent!",
    "uploading": "Uploading...",
    "messageVerify": "Please check your email for verification",
    "errorMap": "No route found between the departure and the arrival!",
    "errorTryAgain": "Error! Please try again!",
    "phoneError": "This phone number is already being used",
    "codeSend": "Code sent",
    "signupSuccess": "Successfully logged in",
    "verifycode": "Wrong code",
    "qrnotfound": "QR code not found",
    "paySuccess": "Payment completed successfully",
    "payError": "Payment error"
  },
  "actionSheet": {
    "sureRemove": "Are you sure you want to remove?",
    "uploadImage": "Upload photo",
    "choosePhoto": "Select photo",
    "takePhoto": "Take photo",
    "warning": "Warning!"
  },
  "login": {
    "title": "Login",
    "forgotPass": "Forgot password",
    "loginFailed": "Invalid username or password!",
    "permission": "You have no permisson to access",
    "createMyAccount": "Create my account",
    "dontHaveAccount": "Don't have an account yet?",
    "loginWith": "Login with",
    "connectWith": "Login with",
    "or": "or",
    "error1": "Enter email/phone number and password",
    "email-or-phonenumber": "Email/Phone number"
  },
  "signIn": "Login",
  "signOut": "Logout",
  "account": {},
  "signUp": {
    "title": "Sign up",
    "register": "Register",
    "type": "Type",
    "desIndividual": "Individual",
    "desEnterprise": "Enterprise",
    "desProfessional": "Professionnal",
    "completeStep": "Complete this step",
    "enterCode": "Enter code",
    "enter4digitCode": "Enter code received"
  },
  "return": "Return",
  "resetPassword": {
    "title": "Reset password"
  },
  "profile": {
    "title": "My profile",
    "edit-profile": "Edit my profile",
    "legal-document": "Legal documents",
    "change-password": "Change password",
    "noti-language": "Notification and Language",
    "bank-info": "Bank card information",
    "contact-selection": "Contact",
    "mentions-legales": "Legal notices",
    "condition-selection": "Conditions of use",
    "bankInfo": {
      "title": "Bank card information",
      "address": "Address",
      "additionalAddress": "Additional address",
      "postalCode": "Postal code",
      "city": "City",
      "iban": "IBAN",
      "saveButton": "Save",
      "savedStatus": "Saved!"
    },
    "changePassword": {
      "title": "Change password",
      "oldPasswordLabel": "Old password",
      "newPasswordLabel": "New password",
      "confirmedPasswordLabel": "Confirm password",
      "saveButton": "Save",
      "msgPasswordLength": "Password must contain at leasr 6 characters!",
      "msgPasswordMustBeTheSame": "Passwords do not match!",
      "msgIncorrectOldPassword": "Wrong old password!"
    },
    "conditions": {
      "title": "Conditions of use"
    },
    "contact": {
      "title": "Contact us",
      "nameLabel": "Name",
      "phoneLabel": "Phone number",
      "emailLabel": "Email",
      "messageLabel": "Your message",
      "sendButton": "Send"
    },
    "information": {
      "title": "Edit my profile",
      "emailLabel": "Email",
      "phoneLabel": "Phone number",
      "firstNameLabel": "First name",
      "lastNameLabel": "Last name",
      "genderLabel": "Sex",
      "maleSelection": "Male",
      "femaleSelection": "Female",
      "birthdayLabel": "Birthday",
      "saveButton": "Save"
    },
    "legalDocuments": {
      "title": "Legal documents",
      "description": "In order to verify your account, you must provide documents below:",
      "statusLabel": "Status ",
      "uploadButton": "Upload",
      "saveButton": "Save",
      "openCameraAction": "Take photo",
      "openGalleryAction": "Upload photo from library",
      "cancelAction": "Cancel"
    },
    "mentionsLegal": {
      "title": "Legal notices"
    },
    "notificationAndLanguage": {
      "notificationTitle": "We'll notify you:",
      "emailSelection": "Via email",
      "smsSelection": "Via sms",
      "pushNotificationSelection": "Via push notification",
      "newsSubscribeTitle": "Subscribe to our newsletters",
      "subscribeNewsLetterSelection": "Via subscription",
      "languageSelection": "Language"
    }
  },
  "modifyProfile": {
    "title": "Edit my profile "
  },
  "menu": {
    "home": "Home",
    "mesLivraisons": "My deliveries",
    "mesAnnonces": "My requests",
    "mesMessage": "My messages",
    "dasboard": "Dashboard",
    "mestrajet": "My routes",
    "notification": "Notifications",
    "history": "History",
    "mesprofile": "Profile & Settings",
    "cgu": "General conditions of use",
    "contact": "Contact us",
    "deconnecter": "Logout"
  },
  "notifications": {
    "title": "Notifications"
  },
  "proposedRouteModal": {
    "title": "Propose a route",
    "yourChoice": "Your choice",
    "yourProposed": "Your proposition"
  },
  "responseRouteModal": {
    "title": "Response",
    "theProposition": "Proposition"
  },
  "newAnnonce": {
    "title": "Send/receive a package",
    "selectPackageType": "Select a type:",
    "package": "Object",
    "room": "Move",
    "typeDescription": "Type description",
    "enterRoomVolume": "Enter room volume",
    "addPackage": "Add an object",
    "addMorePackage": " Add more objects",
    "enterPackageInfo": "Add object's information",
    "quantity": "Quantity",
    "packageName": "Object name",
    "iKnowSize": "I know the size",
    "extimatedSize": "Estimated size",
    "length": "Length",
    "width": "Width",
    "height": "Height",
    "weight": "Weight",
    "address": {
      "title": "Address",
      "DepartureInfomation": "Departure information",
      "ArrivalInfomation": "Arrival information",
      "Date": "Date",
      "SelectDate": "Select date"
    },
    "uploadImage": {
      "title": "Photos",
      "PleaseAddImage": "Please add photo(s)",
      "addNewImage": "Add photos"
    },
    "note": {
      "title": "Note",
      "placeholder": "Add a note"
    },
    "budget": {
      "title": "Budget",
      "placeholder": "Indicate your budget"
    }
  },
  "mesAnnonce": {
    "title": "My requests",
    "status": "Status",
    "all": "ALL",
    "confirmed": "CONFIRMED",
    "online": "ONLINE",
    "paid": "PAID",
    "delivering": "IN THE COURSE OF DELIVERY",
    "delivered": "DELIVERED",
    "refused": "REFUSED",
    "expired": "EXPIRED"
  },
  "detailAnnonce": {
    "title": "Request details",
    "from": "From",
    "to": "To",
    "seeInitnary": "View the route",
    "infomation": "Information",
    "size": "Size",
    "note": "Note",
    "message": "Messages",
    "status": "Status",
    "proposalCost": "Proposed tariff",
    "deliveryDate": "Delivery date",
    "recierDate": "Date of receipt",
    "detailDeparture": "Departure details",
    "detailArrival": "Arrival details",
    "name": "Name",
    "phone": "Phone number",
    "address": "Address",
    "signCompleteOrder": "Sign to complete the order"
  },
  "select": {
    "type": "Select type"
  },
  "dashboard": {
    "title": "Dashboard",
    "myEarn": "My earning",
    "earningThisMonth": "Earning this month",
    "earningThisYear": "Earning this year",
    "currentDeliveringAnnonces": "Current delivering annonces",
    "currentOnlineAnnonces": "Current online annonces"
  },
  "paiements": {
    "title": "Payment",
    "youHaveEarned": "You have earned",
    "registeritnerary": "Propose a route",
    "transfer": "I want to transfer money to my account",
    "transport": "You want to deliver goods?",
    "yourPaiment": "Your provisioned balance is:"
  },
  "mesTrajet": {
    "title": "My routes",
    "status": "Status",
    "from": "Departure:",
    "to": "Arrival:",
    "detailTrajet": "Route details",
    "sstEnligne": "Online",
    "sstPause": "Paused",
    "address": "Address:",
    "address1": "Address 1:",
    "address2": "Address 2:",
    "aroundMyPlace": "Around my address",
    "nearMyHinerary": "On my route",
    "departAddress": "Departure address",
    "arriveAddess": "Arrival address",
    "detourMaximum": "Maximum detour:",
    "startDate": "Start date:",
    "returnDate": "End date:",
    "frequency": "Frequency",
    "isRoundTrip": "Is it round trip?",
    "vehicle": "Vehicle:",
    "suggestion": "Suggestion:",
    "viewAll": "View all"
  },
  "infinityScroll": {
    "text": "Loading..."
  },
  "registerNewTrajetPage1": {
    "title": "Propose a route",
    "aroundMyPlaceOption": "Around my address",
    "nearMyItinerary": "On my route",
    "departureAddressPlaceHolder": "Departure address",
    "arrivalAddressPlaceHolder": "Arrival address",
    "addressPlaceholder": "Address",
    "suggestionRadius": "Suggested radius",
    "suggestionmaximum": "Maximum detour",
    "frequency": "Frequency",
    "isRoundTrip": "Is it round trip?",
    "startDate": "Outbound date",
    "returnDate": "Return date",
    "cancelButton": "Cancel",
    "okButton": "OK",
    "nextButton": "Next",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "enterAddressMessage": "Enter address"
  },
  "registerNewTrajetPage2": {
    "title": "Vehicle",
    "volumeLabel": "Indicate the format you wish to deliver",
    "volumePlaceHolder": "Volume(m3)",
    "estimatedSizeLabel": "Indicate the size",
    "acceptPassengerOption": "Accept passenger?",
    "acceptAnimalOption": "Accept animal?",
    "saveButton": "Save"
  },
  "mes-message": {
    "title": "My messages",
    "type": "Type",
    "me": "Me",
    "select-one": "Select type",
    "seen": "Seen",
    "delivered": "Delivered ",
    "all": "All",
    "ofMyOrder": "Of my orders",
    "ofMyDeliveries": "Of my deliveries",
    "unReadMessage": "Unread messages"
  },
  "detail-message": {
    "offer": "Offer",
    "theOwnerIsChangring": "Loading..."
  },
  "search": {
    "title": "Search",
    "header": "Deliver goods on your route...",
    "departureAddress": "Departure",
    "arrivalAddress": "Arrival",
    "possibleDetour": "Possible detour",
    "possibleDetour50kmOption": "< 50 km",
    "possibleDetour10kmOption": "< 10 km",
    "possibleDetour150kmOption": "< 150 km",
    "possibleDetour100kmOption": "< 100 km",
    "possibleDetour20kmOption": "< 20 km",
    "pre": "Search the package for you",
    "noResult": "No Result Found"
  },
  "suggestion": {
    "title": "Suggestion"
  },
  "qrCode": {
    "title": "QR Code Scanner",
    "scan": "Scan",
    "file": "open from file",
    "galary": "Open from galary"
  },
  "payer": {
    "title": "Pay",
    "totalPrice": "Total",
    "paymentMethod": "Payment method",
    "card": "Bank card",
    "paypal": "Paypal",
    "wallet": "Cash",
    "addCardInfo": "Add bank card information"
  },
  "home": {
    "ecopost": "Ecopost",
    "registerPackage": "Send/Receive package",
    "registerTrajet": "Propose a route",
    "mespaiements": "My payment",
    "unpaidAmount": "You have earned",
    "viewall": "View all transactions"
  },
  "newAddress": {
    "departureinfomation": "Departure infomation",
    "departureAddress": "Departure Address:",
    "departureName": "Departure Name:",
    "departurePhone": "Departure Phone:",
    "departureNote": "Departure Note:",
    "arrivalAddress": "Arrival Address:",
    "arrivalName": "Arrival Name:",
    "arrivalPhone": "Arrival Phone:",
    "arrivalNote": "Arrival Note:",
    "arrivalifomation": "Arrival infomation",
    "isMovingToDeliver": "Possibility of moving to deliver goods to delivery man?",
    "isMovingToReceive": "Possibility of moving to receive goods from delivery man?",
    "isBringingOut": "Need support in bringing goods out of the house?",
    "isBringingInto": "Need support in bringing goods into the house?"
  },
  "route": "Route",
  "returnRoute": "Return route",
  "emailInvalid": "Invalid email address!",
  "fieldRequired": "This field is required!",
  "passwordNotMatch": "This password does not match!",
  "minLenght": "Password must be at least 6 characters long",
  "indentityCard": "IDENTITY CARD",
  "passport": "PASSPORT",
  "residencePermit": "RESIDENCE PERMIT",
  "myLocation": "My location",
  "alert": {
    "turnOnAccess": "Activate access",
    "content": "You previously disabled permissions, to enable this feature click on app settings and grant access again.",
    "notThisTime": "not this time",
    "openSetting": "App settings",
    "doNotUploadImage": "Do not upload images?",
    "addImage": "Add Image",
    "cancelOrder": "Cancel the order",
    "contentCancelOrder": "The operation cancel this order, you can create the order at any time."
  },
  "web": {
    "euoun": "Send or receive a package",
    "trajet": "Suggest a trip",
    "messages": "My messages"
  },
  "and": " And",
  "to": " to",
  "object": " Object",
  "moveRomeWithoutVolume": "Move room",
  "moveRom": "Move room has ",
  "notifyNewOrder": "Your new order is online",
  "trajet-title": {
    "1": "Singular one-way trip around",
    "2": "Singular one-way trip from ",
    "3": "Singular round trip around",
    "4": "Singular round trip from",
    "5": "Regular one-way trip around",
    "6": "Regular one-way trip from",
    "7": "Regular round trip around",
    "8": "Regular round trip around from"
  },
  "back-office": {
    "account": "Account",
    "orders": "Orders",
    "projects": "Projects",
    "order": "Order",
    "payment-request": "Payment Request",
    "statistics": "Statistics",
    "vehicles": "Vehicles",
    "verified": "verified",
    "unverified": "unverified",
    "email": "Email",
    "phone-number": "Phone number",
    "legal-documents": "Legal documents",
    "active": "Active",
    "bank-info": "bank informations",
    "gen-password": " generate password and send",
    "address": "Address",
    "code-portal": "Code Portal",
    "transporter": "Transporter",
    "confirmed": "Confirmed",
    "online": "Online",
    "paid": "Paid",
    "delivering": "Delivering",
    "delivered": "Delivered",
    "refused": "Refused",
    "expired": "Expired",
    "deleted": "Deleted",
    "detail": "Detail",
    "size": "Size",
    "active-online": "Active Online",
    "change-status": "Change status ?",
    "author": "Author",
    "resolve": "Resolve",
    "request-amount": "Request Amount",
    "edit": "Edit",
    "transaction-information": "Transaction information",
    "user": "User",
    "calculating": "Calculating",
    "amount" : "Amount",
    "day": "Day",
    "month": "Month",
    "year": "Year",
    "duration": "Duration",
    "volume": "Volume",
    "display-name": "Display name",
    "estimated-size": "Estimated size",
    "description": "Description",
    "sender": "Sender"

  }
}
export default customEnglishMessages;