import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';
import React from 'react';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    col: {
      list: {
        firstName: "Prénom",
        name: "Nom",
        fullName: "Nom complet",
        address: "Adresse",
        city: "Ville",
        lastName: "Nom",
        role: "Rôle",
        filiale: "Service",
        email: "Email",
        password: "Mot de passe",
        marque: "Marque",
        categorie: "Catégorie",
        sousCategorie: "Sous-Categorie",
        code: "Code",
        composition: "Compo",
        options: "Options",
        prix: "Prix",
        image: "Image",
        stock: "Stock",
        startDate: "Date de début",
        dueDate: "Date d'échéance",
        enCours: "En cours",
        commandes: "Commandes",
        ventes: "Ventes",
        clients: "Clients",
        fichier: "Fichier",
        financementMaximum: "Durée limite de financement",
        confirmPassword: "Confirmer mot de passe",
        date: "Date",
        clien: "Client",
        deadlines: "Délais",
        model: "Modèle",
        total: "Total",
        status: "Statut",
        commission: "Commission",
        margin: "Marge",
        type: "Type",
        reference: "Réf",
        designation: "Désignation",
        category: "Catégorie",
        subCategorie: "S.Catégorie",
        price: "Prix",
        messageType: "Messages",
        restrictions: "Restrictions",
        docVehicle: "Référence véhicule ",
        // docMarque: 'Référence véhicule ',
        description: "Description",
        senderName: "Nom de l’expéditeur",
        message: "Observation",
        artivcle: "Article",
        base: "Base",
        paymentMethod: "Paiement",
        transport: "Transport Usine/ Agence",
        guarantee: "Guarantee contrat",
        securityTest: "Epreuve sécuritée",
        reniuvellement: "Renouvellement Contrat",
        forfait: "Forfait de mise en route",
        condition: "Condition 5",
        txMarge: "Tx Marge",
        marge: "Marge",
        remise: "Remise/Offire",
        img: "Image",
        title: "Titre",
      },
    },
    "form": {
        "email": "Email",
        "emailInvalid": "Email invalide",
        "emailExisted": "Cet email existe déjà",
        "sentEmail": "Entrez votre email pour recevoir les instructions de changer mot de passe",
        "newPassword": "Nouveau mot de passe",
        "oldPassword": "Ancien mot de passe",
        "password": "Mot de passe",
        "changePassword": "Changer mot de passe",
        "confirmPassword": "Confirmer mot de passe",
        "passwordInvalid": "Mot de passe invalide",
        "atLeast": "Comporter au moins 4 caractères",
        "passwordNotMatch": "Les mots de passe ne sont pas identiques!",
        "username": "Nom d'utilisateur",
        "usernameRequired": "Nom d'utilisateur est un champ requis",
        "name": "Nom",
        "nameRequired": "Nom est un champ requis",
        "birthday": "Date de naissance",
        "birthdayRequired": "Date de naissance est un champ requis",
        "telephone": "Téléphone",
        "telephoneRequired": "Numéro de téléphone est un champ requis",
        "telephoneInvalid": "Numéro de téléphone invalide",
        "firstName": "Prénom",
        "firstNameRequired": "Prénom est un champs requis",
        "lastName": "Nom",
        "lastNameRequired": "Nom est un champ requis",
        "sex": "Sexe",
        "sexRequired": "Sexe est un champ requis",
        "address": "Adresse",
        "cityResidence": "Ville de résidence",
        "city": "Ville",
        "addressRequired": "Adresse est un champs requis",
        "fieldRequired": "Ce champs est requis!",
        "accept": "Accepter",
        "addPhoto": "Ajouter une photo de profil",
        "buttonSendEmail": "Envoyer",
        "verify": "Vérifier le code",
        "enterCode": "Entrez le code de vérification",
        "brand": "Marque",
        "country": "Pays",
        "date": "Date",
        "number": "Numéro",
        "nameUp": "Nom",
        "firstNameUp": "Prénom",
        "male": "Homme",
        "female": "Femme",
        "readTerms": "J’ai pris connaissance des CGV",
        "destination": "Arrivée",
        "depart": "Départ",
        "time": "Heure",
        "place": "Nombre de places",
        "invalidLocation": "Location invalide",
        "price": "Prix",
        "message": "Votre message",
        "selectOne": "Choississez une"
      },
      "button": {
        "submit": "Envoyer",
        "choose": "Choisir",
        "chooseTransport": "Choisir transporter",
        "pay": "Payer",
        "ok": "OK",
        "modifier": "Modifier",
        "view": "Voir",
        "remove": "Supprimer",
        "modify": "Modifier",
        "send": "Envoyer",
        "validate": "Valider",
        "continue": "Continuer",
        "register": "S'inscrire",
        "cancel": "Annuler",
        "cancelTrip": "Annuler ce trajet",
        "book": "Réserver",
        "note": "Noter",
        "modifyProfile": "Modifier mon profil",
        "changePassword": "Changer mot de passe",
        "contact": "Contacter",
        "confirm": "Confirmer",
        "signal": "Signaler",
        "makeProposed": "Faire une proposition",
        "acceptAndPay": "Accepter et payer",
        "response": "Réponse",
        "return": "Retour",
        "next": "Suivant",
        "delete": "Supprimer",
        "done": "OK",
        "delivering": "En cours de livraison",
        "delivered": "Livré",
        "sendAgain": "Envoyer à nouveau",
        "allStatus": "Tous les status",
        "refused": "Refusé",
        "accept": "Accepté",
        "make-an-offer": "Faire une offre",
        "save": "Sauvegarder",
        "sender": "Expéditeur",
        "deliveryMan": "Livreur"
      },
      "message": {
        "success": "Succès!",
        "error": "Oups, erreur!",
        "warning": "Alerte!",
        "comingSoon": "Bientôt disponible!",
        "pleaseWait": "Veuillez patienter!",
        "emailSent": "Email envoyé!",
        "uploading": "Veuillez patienter pendant le téléchargement!",
        "messageVerify": "Veuillez vérifier votre boîte email!",
        "errorMap": "Aucun itinéraire n'est trouvé entre le départ et la destination!",
        "errorTryAgain": "Oups, erreur! Veuillez réessayer plus tard!",
        "phoneError": "Numéro de téléphone déjà utilisé",
        "codeSend": "Code envoyé",
        "signupSuccess": "Connexion avec succès",
        "verifycode": "Code de vérification incorrecte",
        "qrnotfound": "QR code pas trouvé",
        "paySuccess": "Paiement effectué avec succès",
        "payError": "Erreur de paiement"
      },
      "actionSheet": {
        "sureRemove": "Êtes-vous sûr de supprimer?",
        "uploadImage": "Télécharger une photo",
        "choosePhoto": "Choisir une photo",
        "takePhoto": "Prendre une photo",
        "warning": "Alerte!"
      },
      "login": {
        "title": "Connexion",
        "forgotPass": "Mot de passe oublié",
        "loginFailed": "Nom d'utilisateur ou mot de passe invalide!",
        "permission": "Vous n'avez pas l'autorisation d'accéder à ce contenu!",
        "createMyAccount": "Créer mon compte",
        "dontHaveAccount": "Vous n'avez pas encore de compte?",
        "loginWith": "Se connecter avec",
        "connectWith": "Se connecter avec",
        "or": "ou",
        "error1": "Entrez email/numéro de téléphone et mot de passe",
        "email-or-phonenumber": "Email ou Numéro de téléphone"
      },
      "signIn": "Se connecter",
      "signOut": "Se déconnecter",
      "account": {},
      "signUp": {
        "title": "Inscription",
        "register": "S'inscrire",
        "type": "Type",
        "desIndividual": "Particulier",
        "desEnterprise": "Entreprise",
        "desProfessional": "Professionnel",
        "completeStep": "Compléter les étapes",
        "enterCode": "Entrez le code",
        "enter4digitCode": "Entrez le code reçu"
      },
      "return": "Retour",
      "resetPassword": {
        "title": "Réinitialiser le mot de passe"
      },
      "profile": {
        "title": "Mon profil",
        "edit-profile": "Editer le profil",
        "legal-document": "Documents légaux",
        "change-password": "Changer mot de passe",
        "noti-language": "Notifications and Langues",
        "bank-info": "Informations de carte bancaire",
        "contact-selection": "Contact",
        "mentions-legales": "Mentions légales",
        "condition-selection": "Conditions d'utilisations",
        "bankInfo": {
          "title": "Informations de carte bancaire",
          "address": "Adresse",
          "additionalAddress": "Adresse supplémentaire",
          "postalCode": "Code postal",
          "city": "Ville",
          "iban": "IBAN",
          "saveButton": "Sauvegarder",
          "savedStatus": "Sauvegardé!"
        },
        "changePassword": {
          "title": "Changer mot de passe",
          "oldPasswordLabel": "Ancien mot de passe",
          "newPasswordLabel": "Nouveau mot de passe",
          "confirmedPasswordLabel": "Confirmer mot de passe",
          "saveButton": "Sauvegarder",
          "msgPasswordLength": "Mot de passe doit comporter au moins 6 caractères!",
          "msgPasswordMustBeTheSame": "Mot de passe et confirmation du mot de passe doivent être identiques!",
          "msgIncorrectOldPassword": "Ancien mot de passe est incorrect!"
        },
        "conditions": {
          "title": "Conditions d'utilisations"
        },
        "contact": {
          "title": "Titre",
          "nameLabel": "Nom",
          "phoneLabel": "Numéro de téléphone",
          "emailLabel": "Email",
          "messageLabel": "Votre message",
          "sendButton": "Envoyer"
        },
        "information": {
          "title": "Éditer le profil",
          "emailLabel": "Email",
          "phoneLabel": "Numéro de téléphone",
          "firstNameLabel": "Nom",
          "lastNameLabel": "Prénom",
          "genderLabel": "Sexe",
          "maleSelection": "Homme",
          "femaleSelection": "Femme",
          "birthdayLabel": "Date de naissance",
          "saveButton": "Sauvegarder"
        },
        "legalDocuments": {
          "title": "Documents légaux",
          "description": "Afin de vérifier votre compte, vous devez fournir le(s) document(s) suivant(s).",
          "statusLabel": "Statut ",
          "uploadButton": "Télécharger",
          "saveButton": "Sauvegarder",
          "openCameraAction": "Prendre le photo",
          "openGalleryAction": "Importer à partir de la bibliothèque",
          "cancelAction": "Annuler"
        },
        "mentionsLegal": {
          "title": "Mentions Légales"
        },
        "notificationAndLanguage": {
          "notificationTitle": "Nous vous avisons:",
          "emailSelection": "Via email",
          "smsSelection": "Via sms",
          "pushNotificationSelection": "Via notification push",
          "newsSubscribeTitle": "Abonnez-vous à notre lettre d'information",
          "subscribeNewsLetterSelection": "Via subscription",
          "languageSelection": "Langues"
        }
      },
      "modifyProfile": {
        "title": "Modifier mon profil "
      },
      "menu": {
        "home": "Accueil",
        "mesLivraisons": "Mes livraisons",
        "mesAnnonces": "Mes annonces",
        "mesMessage": "Mes messages",
        "dasboard": "Tableau de bord",
        "mestrajet": "Mes trajets",
        "notification": "Notifications",
        "history": "Historique",
        "mesprofile": "Profil & Paramètres",
        "cgu": "CGU",
        "contact": "Nous contacter",
        "deconnecter": "Se déconnecter"
      },
      "notifications": {
        "title": "Notifications"
      },
      "proposedRouteModal": {
        "title": "Proposer un trajet",
        "yourChoice": "Votre choix",
        "yourProposed": "Votre proposition"
      },
      "responseRouteModal": {
        "title": "Réponse",
        "theProposition": "La proposition"
      },
      "newAnnonce": {
        "title": "Expédier un colis",
        "selectPackageType": "Choisir un type...",
        "package": "Objets",
        "room": "Déménagement",
        "typeDescription": "Description du type",
        "enterRoomVolume": "Entrer le volume de la pièce",
        "addPackage": "Ajouter un objet",
        "addMorePackage": " Ajouter plus d'objets",
        "enterPackageInfo": "Ajouter les informations de l'objet",
        "quantity": "Quantité",
        "packageName": "Nom de l'objet",
        "iKnowSize": "Je connais ses dimensions",
        "extimatedSize": "Taille estimée",
        "length": "Longueur",
        "width": "Largeur",
        "height": "Hauteur",
        "weight": "Poids",
        "address": {
          "title": "Adresse",
          "DepartureInfomation": "Au départ",
          "ArrivalInfomation": "À l'arrivée",
          "Date": "Date",
          "SelectDate": "Choisir une date"
        },
        "uploadImage": {
          "title": "Photos",
          "PleaseAddImage": "Ajouter des photos à votre annonce",
          "addNewImage": "Ajouter des photo"
        },
        "note": {
          "title": "Notes",
          "placeholder": "Ajouter une note"
        },
        "budget": {
          "title": "Budget",
          "placeholder": "Indiquer votre budget"
        }
      },
      "mesAnnonce": {
        "title": "Mes annonces",
        "status": "Statut",
        "all": "TOUS",
        "confirmed": "CONFIRMÉ",
        "online": "EN LIGNE",
        "paid": "PAYÉ",
        "delivering": "EN COURS DE LIVRAISON",
        "delivered": "LIVRÉ",
        "refused": "REFUSÉ",
        "expired": "EXPIRÉ"
      },
      "detailAnnonce": {
        "title": "Détails de l'annonce",
        "from": "Départ",
        "to": "Arrivée",
        "seeInitnary": "Voir l'itinéraire",
        "infomation": "Informations",
        "size": "Taille",
        "note": "Notes",
        "message": "Messages",
        "status": "Statut",
        "proposalCost": "Tarif proposé",
        "deliveryDate": "Date de livraison",
        "recierDate": "Date de réception",
        "detailDeparture": "Au départ",
        "detailArrival": "À l'arrivée",
        "name": "Nom",
        "phone": "Téléphone",
        "address": "Adresse",
        "signCompleteOrder": "Signez pour terminer la commande"
      },
      "select": {
        "type": "Choisir un type"
      },
      "dashboard": {
        "title": "Tableau de bord",
        "myEarn": "Mon revenu",
        "earningThisMonth": "Revenu de ce mois",
        "earningThisYear": "Revenu de cette année",
        "currentDeliveringAnnonces": "Livraison en cours",
        "currentOnlineAnnonces": "Annonces en ligne actuelles"
      },
      "paiements": {
        "title": "Paiement",
        "youHaveEarned": "[FR] You have earned",
        "registeritnerary": "Proposer un trajet",
        "transfer": "Je veux demander un virement à mon compte bancaire",
        "transport": "Vous souhaitez transporter des objets?",
        "yourPaiment": "Votre solde provisionné est"
      },
      "mesTrajet": {
        "title": "Mes trajets",
        "status": "Statut",
        "from": "Départ:",
        "to": "Arrivée:",
        "detailTrajet": "Détail du trajet",
        "sstEnligne": "En ligne",
        "sstPause": "En pause",
        "address": "Adresse:",
        "address1": "Adresse 1:",
        "address2": "Adresse 2:",
        "aroundMyPlace": "Autour de moi",
        "nearMyHinerary": "Sur mon trajet",
        "departAddress": "Adresse du départ",
        "arriveAddess": "Adresse de l'arrivée",
        "detourMaximum": "Détour maximum:",
        "startDate": "Aller le",
        "returnDate": "Retour le",
        "frequency": "Fréquence",
        "isRoundTrip": "Aller-retour?",
        "vehicle": "Véhicule:",
        "suggestion": "Suggestion:",
        "viewAll": "Voir tous"
      },
      "infinityScroll": {
        "text": "En chargement..."
      },
      "registerNewTrajetPage1": {
        "title": "Proposer un trajet",
        "aroundMyPlaceOption": "Autour de moi",
        "nearMyItinerary": "Sur mon trajet",
        "departureAddressPlaceHolder": "Adresse du départ",
        "arrivalAddressPlaceHolder": "Adresse de l'arrivée",
        "addressPlaceholder": "Adresse",
        "suggestionRadius": "Rayon suggéré",
        "suggestionmaximum": "Détour maximum",
        "frequency": "Fréquence",
        "isRoundTrip": "Aller-retour?",
        "startDate": "Date du trajet aller",
        "returnDate": "Date du trajet retour",
        "cancelButton": "Annuler",
        "okButton": "OK",
        "nextButton": "Suivant",
        "monday": "Lundi",
        "tuesday": "Mardi",
        "wednesday": "Mercredi",
        "thursday": "Jeudi",
        "friday": "Vendredi",
        "saturday": "Samedi",
        "sunday": "Dimanche",
        "enterAddressMessage": "Entrer l'adresse"
      },
      "registerNewTrajetPage2": {
        "title": "Véhicule",
        "volumeLabel": "Indiquer le format vous pouvez transporter",
        "volumePlaceHolder": "Volume(m3)",
        "estimatedSizeLabel": "Indiquer la taille",
        "acceptPassengerOption": "Accepter passager?",
        "acceptAnimalOption": "Accepter animaux?",
        "saveButton": "Sauvegarder"
      },
      "mes-message": {
        "title": "Mes messages",
        "type": "Type",
        "seen": "Lus",
        "me": "Moi",
        "select-one": "Choisir un type",
        "delivered": "délivré",
        "all": "Tous",
        "ofMyOrder": "De mes annonces",
        "ofMyDeliveries": "De mes livraisons",
        "unReadMessage" :"Messages non lus"
    
      },
      "detail-message": {
        "offer": "Offre",
        "theOwnerIsChangring": "Chargé..."
      },
      "search": {
        "title": "Recherche",
        "header": "Transporter des colis sur votre trajet...",
        "departureAddress": "Départ",
        "arrivalAddress": "Arrivée",
        "possibleDetour": "Détour possible",
        "possibleDetour50kmOption": "< 50 km",
        "possibleDetour10kmOption": "< 10 km",
        "possibleDetour150kmOption": "< 150 km",
        "possibleDetour100kmOption": "< 100 km",
        "possibleDetour20kmOption": "< 20 km",
        "pre": "Trouvez le forfait qui vous convient",
        "noResult": "Aucun résultat trouvé"
      },
      "suggestion": {
        "title": "Suggestion"
      },
      "qrCode": {
        "title": "QR Code Scanner",
        "scan": "Scanner",
        "file": "ouvrir à partir d'un fichier",
        "galary": "Importer à partir de la bibliothèque"
      },
      "payer": {
        "title": "Payer",
        "totalPrice": "Total",
        "paymentMethod": "Mode de paiement",
        "card": "CB",
        "paypal": "Paypal",
        "wallet": "Espèces",
        "addCardInfo": "Ajouter informations de la carte"
      },
      "home": {
        "ecopost": "Ecopost",
        "registerPackage": "Expédier un colis",
        "registerTrajet": "Proposer un trajet",
        "mespaiements": "Mes paiements",
        "unpaidAmount": "Gagné",
        "viewall": "Voir toutes transactions"
      },
      "newAddress": {
        "departureinfomation": "Information du départ:",
        "departureAddress": "Adresse du départ:",
        "departureName": "Nom:",
        "departurePhone": "Phone:",
        "departureNote": "Notes:",
        "arrivalAddress": "Adresse de l'arrivée:",
        "arrivalName": "Nom:",
        "arrivalPhone": "Phone:",
        "arrivalNote": "Notes:",
        "arrivalifomation": "Information de l'arrivée:",
        "isMovingToDeliver": "Possibilité de se déplacer pour remettre les biens au (co)transporteur ?",
        "isMovingToReceive": "Possibilité d'aller à la rencontre du (co)transporteur pour récupérer les biens ?",
        "isBringingOut": "Besoin d'aide pour sortir les biens du logement ?",
        "isBringingInto": "Besoin d'aide pour déposer les biens dans le logement ?"
      },
      "route": "Route",
      "returnRoute": "Route de retour",
      "emailInvalid": "Adresse email invalide",
      "fieldRequired": "Ce champs est obligatoire!",
      "passwordNotMatch": "Les mots de passe ne sont pas identiques!",
      "minLenght": "Le mot de passe doit comporter au moins 6 caractères",
      "indentityCard": "CARTE D'IDENTITÉ",
      "passport": "PASSEPORT",
      "residencePermit": "PERMIS DE RÉSIDENCE",
      "alert": {
        "turnOnAccess": "Activer l'accès",
        "content": "Vous avez précédemment désactivé les autorisations, pour activer cette fonctionnalité, cliquez sur les paramètres de l'application et accordez à nouveau l'accès.",
        "notThisTime": "pas cette fois",
        "openSetting": "Ouvrir les paramètres",
        "doNotUploadImage": "Vous ne téléchargez pas d'images?",
        "addImage": "Ajouter une image",
        "cancelOrder": "Annuler la commande",
        "contentCancelOrder": "L'opération annuler cette commande, vous pouvez créer la commande à tout moment.",
        "location": "Vous avez précédemment désactivé les autorisations, pour activer cette fonctionnalité, cliquez sur les paramètres de l'application et accordez à nouveau l'accès."
      },
      "web": {
        "euoun": "Expédier ou rececvoir un colis",
        "trajet": "Proposser un trajet",
        "messages": "Voir mes messages"
      },
      "myLocation": "Ma localisation",
      "and": " Et",
      "to": "à",
      "object": " Objet",
      "moveRomeWithoutVolume": "Déplacer la pièce",
      "moveRom": "Déplacer la salle a ",
      "notifyNewOrder": "Votre nouvelle commande est en ligne",
      "trajet-title":{
        "1": "Un trajet simple singulier autour de",
        "2": "Un trajet simple singulier de",
        "3": "Un trajet-retour singulier autour de",
        "4": "Un trajet-retour singulier de",
        "5": "Un trajet simple régulier autour de",
        "6": "Un trajet simple régulier de",
        "7": "Un trajet-retour régulier autour de",
        "8": "Un trajet-retour régulier de"
      },
      "back-office":{
        "account": "Compte",
        "orders": "Annonces",
        "order": "Annonce",
        "payment-request": "Demande de paiement",
        "statistics": "Statistiques",
        "vehicles" : "Véhicules",
        "verified": "vérifié",
        "unverified" : "non vérifié",
        "email" : "Email", 
        "phone-number": "Numéro de téléphone", 
        "legal-documents": "Documents légaux",
        "active" : "Actif",
        "bank-info" : "informations bancaires",
        "gen-password": "générer un mot de passe et envoyer",
        "address": "Adress",
        "code-portal": "Portail de code",
        "transporter" : "Transporteuse",
        "confirmed": "Confirmé",
        "online": "En ligne",
        "paid": "Payé",
        "delivering": "Livrer",
        "delivered": "Livré",
        "refused": "Refusé",
        "expired": "Expiré",
        "deleted": "Supprimé",
        "detail": "Détail",
        "size": "Taille",
        "active-online": "Actif en ligne",
        "change-status": "Changer de statut ?",
        "author": "Auteur",
        "request-amount": "Montant de la demande",
        "resolve": "Résoudre",
        "edit" :"Éditer",
        "transaction-information": "Informations sur les transactions",
        "user": "Utilisateur",
        "calculating": "Calculateur",
        "amount" : "Montant",
        "day": "Journée",
        "month": "Mois",
        "year": "An",
        "duration": "Durée",
        "volume": "Le volume",
        "display-name": "Afficher un nom",
        "estimated-size": "Taille estimée",
        "description": "La description",
        "sender": "Expéditeur"
      }
}
export default customFrenchMessages