import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { Admin, Resource, resolveBrowserLocale } from "react-admin";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/App.css";
import loopbackClient, { storage } from "./react-admin-loopback";
import {
  AccountCreate,
  AccountEdit,
  AccountList,
} from "./pages/accounts/accounts";
import { API_URL } from "./constant";
import fetchJson from "./react-admin-loopback/fetch";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import "../src/App.css";
import "react-notifications/lib/notifications.css";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Dashboard } from "./dashboard/dashboard";
import { createBrowserHistory as createHistory } from "history";
import NotFound from "./admin/NotFound";
import SettingsIcon from "@material-ui/icons/Settings";
import Menu from "./admin/menu";
import customRoutes from "./admin/custom-routers";
import {
  ListVehicle,
  EditVehicles,
  CreateVehicles,
} from "./pages/vehicles/vehicles";
import MyLayout from "./admin/my-layout";
import Contacts from "./pages/contacts";
import frenchMessages from "./services/i18n/fr";
import englishMessages from "./services/i18n/en";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { MyLoginPage } from "./login/login";
import { usePermissions } from "react-admin";
import authProvider from "./react-admin-loopback/authProvider";
import { RoleMappingsCreate } from "./pages/roles/rolemappings";
import { ProjectList } from "./pages/projects/Projects";
import { CreateProject } from "./pages/projects/CreateProject";
import { CsvList } from "./pages/csv/csv";

const withUpload = (
  requestHandler: (type: any, resource: any, params: any) => any
) => async (
  type: string,
  resource: string,
  params: { data: any; previousData: any }
) => {
  if (
    resource === "Accounts" &&
    (type === "UPDATE" || type === "CREATE")
  ) {
    const { avatar, ...data } = params.data;
    if (!avatar.rawFile.type.startsWith("image")) {
      throw new Error(
        "The uploaded media does not match the selected media type"
      );
      return Promise.resolve({ data: params.previousData });
    }

    const formData = new FormData();
    formData.append("file", avatar.rawFile);
    const json = await fetchJson(
      `${API_URL}/Containers/pic-avatar/upload`,
      { method: "POST", body: formData }
    );
    return requestHandler(type, resource, {
      ...params,
      data: {
        ...data,
        avatar: json.json.result.files.file[0].name,
      },
    });
  }

  return requestHandler(type, resource, params);
};

const initialState = {
  theme: "dark",
  grid: 5,
};

const history = createHistory();
const messages = {
  fr: frenchMessages,
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => {
  // locale = "fr";
  return messages[locale];
});

const App = () => {
  return (
    <Fragment>
      {useMemo(
        () => (
          <Admin
            locale={resolveBrowserLocale()}
            i18nProvider={i18nProvider}
            customRoutes={customRoutes}
            loginPage={MyLoginPage}
            layout={MyLayout}
            history={history}
            catchAll={NotFound}
            initialState={initialState}
            dataProvider={withUpload(loopbackClient())}
            // dashboard={}
            authProvider={authProvider}
          >
            {(permissions) => [
              permissions === "SUPERADMIN" || permissions === "ADMIN" ? (
                <Resource
                  name="Accounts"
                  list={AccountList}
                  create={AccountCreate}
                  edit={AccountEdit}
                />
              ) : (
                <Resource name="Accounts" />
              ),
              <Resource
                name="Projects"
                list={ProjectList}
                create={CreateProject}
              />,
              <Resource
                name="CSV"
                list={CsvList}
              />,
              <Resource name="RoleMappings" create={RoleMappingsCreate} />,
              <Resource name="Roles" />,
            ]}
          </Admin>
        ),
        []
      )}
    </Fragment>
  );
};

export default App;
