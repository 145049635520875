import React, { Component } from "react";
import {
  Create,
  Datagrid,
  Edit,
  ImageField,
  ImageInput,
  List,
  PasswordInput,
  SelectInput,
  showNotification,
  SimpleForm,
  TextInput,
  useRefresh,
  EditButton,
  useRedirect,
  TextField,
  Filter,
  SearchInput,
  useTranslate,
  useNotify,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import "../accounts/account.scss";
import { Link } from "react-router-dom";
import { DateInput } from "../../react-admin-date-inputs";
import Button from "@material-ui/core/Button";
import { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  makeStyles,
  Theme,
  Paper,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ClearIcon from "@material-ui/icons/Clear";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckIcon from "@material-ui/icons/Check";
import ContentFilter from "@material-ui/icons/FilterList";
import { Form } from "react-final-form";
import { API_URL } from "../../constant";
import SetActiveButtons from "./set-active";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import { storage } from "../../react-admin-loopback";
const apiContainetAvatar = `${API_URL}/Containers/pic-avatar/download`;
const AvatarShow = ({ record = {}, size }: any) => {
  const accessToken = storage.load("lbtoken").id;
  size = size || 32;
  const extra = { label: "avatar" };
  return record.avatar ? (
    <Avatar
      {...extra}
      // src={`${API_URL}/Containers/photos/download/${record.avatar}`}
      src={`${apiContainetAvatar}/${record.avatar}?access_token=${accessToken}`}
      style={{
        width: size,
        height: size,
      }}
    />
  ) : null;
};

const DisplayName = ({ record = {} }: any) => {
  return <div>{`${record.firstName} ${record.lastName}`}</div>;
};

const StatusVerify = ({ record = {} }: any) => {
  let statusEmailVerify: string;
  let statusLegalDocumentsVerify: string;
  const translate = useTranslate();
  record.isEmailVerified
    ? (statusEmailVerify = `${translate("back-office.email")} ${translate(
        "back-office.verified"
      )}`)
    : (statusEmailVerify = `${translate("back-office.email")} ${translate(
        "back-office.unverified"
      )}`);
  record.isLegalDocumentVerified
    ? (statusLegalDocumentsVerify = `${translate(
        "back-office.account"
      )} ${translate("back-office.verified")}`)
    : (statusLegalDocumentsVerify = `${translate(
        "back-office.account"
      )} ${translate("back-office.unverified")}`);
  return <div>{`${statusEmailVerify} - ${statusLegalDocumentsVerify}`}</div>;
};

// const accountDetail = (props: any) =>  <Link to={`/Accounts/${props.record.id}`} {...props} />

const PostFilterButton = ({ showFilter }: { showFilter: any }) => (
  <Button
    size="small"
    color="primary"
    onClick={() => showFilter("main")}
    startIcon={<ContentFilter />}
  >
    Filter
  </Button>
);

const PostFilterForm = ({
  displayedFilters,
  filterValues,
  setFilters,
  hideFilter,
}: {
  displayedFilters: any;
  filterValues: any;
  setFilters: any;
  hideFilter: any;
  open: any;
}) => {
  if (!displayedFilters.main) return null;
  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      hideFilter("main");
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div className="w-100">
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }: { handleSubmit: any }) => (
          <form onSubmit={handleSubmit}>
            <Box component="span" mr={1}>
              <TextInput
                resettable
                helperText={false}
                source="phoneNumber"
                label="Search"
              />
            </Box>
            <Box component="span" mr={1}>
              <SelectInput
                source="type"
                label="Select type"
                choices={[
                  { id: "INDIVIDUAL", name: "INDIVIDUAL" },
                  { id: "PROFESSIONAL", name: "PROFESSIONAL" },
                  { id: "ENTERPRISE", name: "ENTERPRISE" },
                ]}
              />
            </Box>
            <Box component="span" mr={1}>
              <SelectInput
                source="isLegalDocumentVerified"
                label="Status verified"
                choices={[
                  { id: "true", name: "Verified" },
                  { id: "false", name: "Unverified" },
                ]}
              />
            </Box>
            <Box mt={1} mr={1} mb={1}>
              <Button variant="outlined" color="primary" type="submit">
                Apply filter
              </Button>
              <a title="Clear filter" onClick={resetFilter}>
                {" "}
                <ClearIcon />
              </a>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const PostFilter = (props: any) => {
  return props.context === "button" ? (
    <PostFilterButton {...props} />
  ) : (
    <PostFilterForm {...props} open />
  );
};

const PostFilters = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput
        source="username"
        alwaysOn
        placeholder={`${translate("search.title")} ${translate(
          "form.username"
        )}`}
      />
    </Filter>
  );
};

const PostBulkActionButtons = (props) => {
  return (
    <Fragment>
      <SetActiveButtons {...props} />
    </Fragment>
  );
};

export const AccountList = (props) => {
  const translate = useTranslate();
  return (
    <List
      exporter={false}
      {...props}
      filters={<PostFilters />}
      sort={{ field: "createdAt", order: "DESC" }}
      bulkActionButtons={<PostBulkActionButtons />}
    >
      <Datagrid>
        <AvatarShow />
        <DisplayName label="profile.contact.nameLabel" />
        <TextField source="username" label="UserName" />

        <EditButton label="back-office.edit" />
      </Datagrid>
    </List>
  );
};

const accountRedirect = (props: any) => <Link to="/Accounts" {...props} />;

export const AccountEdit = (props: any) => {
  const arr = [1, 2, 3, 4];
  const translate = useTranslate();
  return (
    <Edit {...props}>
      {/* <SimpleForm {...props} toolbar={<CustomToolbar />}> */}
      <SimpleForm {...props}>
        <div className="row">
          <Button component={accountRedirect}>
            <ArrowBackIcon />
          </Button>
          <h3>{translate("back-office.account")}</h3>
        </div>
        <div id="showDetailAccount" className="row">
          <div className="col-6 left">
            <TextInput disabled source="email" />
            <div className="row">
              <div className="col-6">
                {" "}
                <TextInput
                  source="firstName"
                  fullWidth
                  label="form.firstName"
                />
              </div>
              <div className="col-6">
                {" "}
                <TextInput source="lastName" fullWidth label="form.lastName" />
              </div>
            </div>
            <TextInput source="username" fullWidth label="form.username" />
            <ImageInput source="avatar" accept="image/*" multiple={false}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export const AccountCreate = (props: any) => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();

  const onSuccess = ({ data }) => {
    redirect(`/RoleMappings/create?principalId=${data.id}`);
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm {...props}>
        <div className="row">
          <Button component={accountRedirect}>
            <ArrowBackIcon />
          </Button>
          <h3>{translate("back-office.account")}</h3>
        </div>
        <div id="showDetailAccount" className="row">
          <div className="col-6 left">
            <div className="row">
              <div className="col-6">
                {" "}
                <TextInput source="email" fullWidth label="form.email" />
              </div>
              <div className="col-6">
                {" "}
                <PasswordInput source="password" fullWidth />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {" "}
                <TextInput
                  source="firstName"
                  fullWidth
                  label="form.firstName"
                />
              </div>
              <div className="col-6">
                {" "}
                <TextInput source="lastName" fullWidth label="form.lastName" />
              </div>
            </div>
            <TextInput source="username" fullWidth label="form.username" />
            <ImageInput source="avatar" accept="image/*" multiple={false}>
              <ImageField className="img-avt" source="src" title="title" />
            </ImageInput>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
