import React, {
  useCallback,
  Fragment,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  EditButton,
  useRedirect,
} from "react-admin";
import { storage } from "../../react-admin-loopback";

export const ApiKey = (props) => {
  const [apiKeyDataLogin, setApiKeyTypeDataLogin] = useState();

  useEffect(() => {
    const user = storage.load("user");
    setApiKeyTypeDataLogin(user.apiKey);
  }, []);
  return (
    <div style={{ marginTop: 20 }}>
      Your API key is: <b>{apiKeyDataLogin}</b>
    </div>
  );
};
