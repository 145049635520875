import React, {
  useCallback,
  Fragment,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import { DataGrid } from "@material-ui/data-grid";
import "./csv.scss";
import { parse } from "query-string";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import Button from "@material-ui/core/Button";
import { useRedirect, useNotify, usePermissions } from "react-admin";
import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import CircularProgress from "@material-ui/core/CircularProgress";

let count = 0;
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const CsvList = (props) => {
  const API = new LoopbackAPI();
  const [data, setData] = useState(Array());
  const [columns, setColumns] = useState([]);
  const { name: post_id_string } = parse(props.location.search);
  const [name, setName] = useState(post_id_string);
  const classes = useStyles();
  const [dataName, setDataName] = useState(Array());
  const [dataEmail, setDataEmail] = useState(Array());
  const [dataCivili, setDataCivili] = useState(Array());
  const [dataPrenom, setDataPrenom] = useState(Array());
  const [dataNom, setDataNom] = useState(Array());
  const [dataCode, setDataCode] = useState(Array());
  const redirect = useRedirect();
  const [dataOriginal, setDataOriginal] = useState(Array());
  const [countRow, setCountRow] = useState(0);
  const [projectId, setProjectId] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState(Array());
  const notify = useNotify();
  const { permissions } = usePermissions();
  const [email, setEmail] = useState("");
  const [civi, setCivi] = useState("");
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [code, setCode] = useState("");
  const [dataExport,setDataExport] = useState(Array())
  const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

  const fetchData = useCallback(async () => {
    // const query = {
    //   filter: `{"where": {"name": "${name}"}}`,
    // };
    setLoading(true);
    await API.projects
      .projectFind()
      .then(async (results) => {
        setProjectId(get(results, "data[0].id"));
        const filter = {
          filter: `{"where":{"projectId":"${get(
            results,
            "data[0].id"
          )}"},"limit" : 100,"skip": 0}`,
        };

        await API.trackRecords
          .trackRecordCount({
            where: `{
              "projectId" : "${get(results, "data[0].id")}"
            }`,
          })
          .then((result) => {
            if (result && result.data && result.data.count) {
              setCountRow(result.data.count);
            }
          });
        await API.trackRecords
          .trackRecordFind(filter)
          .then((data) => {
            const dataColumns = get(data, "data").map((item) => {
              const listKey = Object.keys(item).filter(
                (i) =>
                  i !== "batchId" &&
                  i !== "id" &&
                  i !== "createdAt" &&
                  i !== "updatedAt" &&
                  i !== "projectId" &&
                  i !== "extraColumns"
              );
              return Object.assign(
                {},
                listKey
                  .map((itemKey) => {
                    return item[`${itemKey}`];
                  })
                  .filter((i) => i !== null)
              );
            });

            const dataCsv = dataColumns.map((item, index) => {
              return {
                ...item,
                id: index + 1,
              };
            });
            setLoading(false);

            setData(dataCsv);
            if (get(results, "data[0].headers").length > 0) {
              const dataColumns = get(results, "data[0].headers").map(
                (i, index) => {
                  return {
                    field: Number(i.id) >= 0 ? Number(i.id) : index,
                    headerName: i.columnName,
                    width: 200,
                  };
                }
              );

              dataColumns.unshift({
                field: "id",
                headerName: "Id",
                width: 100,
              });
              setColumns(dataColumns);

              const dataOrigin = dataCsv.map((item) => {
                const newOb = dataColumns.map((i) => {
                  let key = i.headerName;
                  return {
                    [key]: item[`${i.field}`],
                  };
                });
                return newOb.reduce(function (result, elem) {
                  let key = Object.keys(elem)[0];
                  if (key !== "Id") result[key] = elem[key];
                  return result;
                }, {});
              });
              setDataOriginal(dataOrigin);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, [API.projects, API.trackRecords]);

  // useEffect(() => {
  //   // if (name) {
  //   fetchData();
  //   // }
  // }, []);

  const fetchProject = useCallback(async () => {
    // if (dataProjects.length > 0) return;
    await API.projects
      .projectFind()
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          setHeader(get(result,'data[0].headers'))
        }
      })
      .catch();
  }, [API.projects]);

  useEffect(()=>{
    fetchProject();
  },[])

  // const onFocus = useCallback(async () => {
  //   if (dataName.length > 0) return;
  //   await API.projects
  //     .projectFind()
  //     .then((result) => {
  //       if (result && result.data && result.data.length > 0) {
  //         setDataName(result.data.map((item) => item.name));
  //       }
  //     })
  //     .catch();
  // }, [API.projects, dataName.length]);

  // const onInputChange = useCallback(
  //   (params, value) => {
  //     if (!!dataName.includes(value)) {
  //       setData([]);
  //       setColumns([]);
  //       setCountRow(0);
  //       setPage(1);
  //       fetchData();
  //       setName(value);
  //     }
  //   },
  //   [dataName, fetchData]
  // );

  // const onChange = useCallback((event, value, reason) => {
  //   if (reason === "clear") {
  //     setData([]);
  //     setColumns([]);
  //     setCountRow(0);
  //     setPage(1);
  //   }
  // }, []);

  const handleImport = useCallback(() => {
    redirect(`/CSV/import`);
  }, [redirect]);

  const onPageChange = useCallback(
    async (params) => {
      if (count !== params.page) {
        count = params.page;
        if (page < params.page) {
          if (params.page !== 1) {
            const filter = {
              filter: `{"where":{"projectId":"${projectId}"},"limit" : 100,"skip": ${
                (params.page - 1) * 100
              }}`,
            };
            setLoading(true);
            await API.trackRecords.trackRecordFind(filter).then((result) => {
              setLoading(false);
              const dataColumns = get(result, "data").map((item) => {
                const listKey = Object.keys(item).filter(
                  (i) =>
                    i !== "batchId" &&
                    i !== "id" &&
                    i !== "createdAt" &&
                    i !== "updatedAt" &&
                    i !== "projectId" &&
                    i !== "extraColumns"
                );
                return Object.assign(
                  {},
                  listKey
                    .map((itemKey) => {
                      return item[`${itemKey}`];
                    })
                    .filter((i) => i !== null)
                );
              });

              const dataCsv = dataColumns.map((item, index) => {
                return {
                  ...item,
                  id: index + (params.page - 1) * 100 + 1,
                };
              });
              setPage(params.page);
              setData(data.concat(dataCsv));
            });
          }
        }
      }
    },
    [API.trackRecords, data, page, projectId]
  );

  const handleExport = useCallback(async () => {
    // setDataOriginal(data);
    // let i = 0;
    // let dataOriginCsv = [];

    // while (i < countRow) {
    //   const filter = {
    //     filter: `{"where":{"projectId":"${projectId}"},"limit" : 10000,"skip": ${i}}`,
    //   };
    //   i = i + 10000;
    //   await API.trackRecords
    //     .trackRecordFind(filter)
    //     // eslint-disable-next-line no-loop-func
    //     .then((result) => {
    //       const dataCsv = get(result, "data").map((item) => {
    //         const listKey = Object.keys(item).filter(
    //           (i) =>
    //             i !== "batchId" &&
    //             i !== "id" &&
    //             i !== "createdAt" &&
    //             i !== "updatedAt" &&
    //             i !== "projectId" &&
    //             i !== "extraColumns"
    //         );
    //         return Object.assign(
    //           {},
    //           listKey
    //             .map((itemKey) => {
    //               return item[`${itemKey}`];
    //             })
    //             .filter((i) => i !== null)
    //         );
    //       });
    //       const dataOrigin = dataCsv.map((item) => {
    //         const newOb = columns.map((i) => {
    //           let key = get(i, "headerName");
    //           return {
    //             [key]: item[`${get(i, "field")}`],
    //           };
    //         });
    //         return newOb.reduce(function (result, elem) {
    //           let key = Object.keys(elem)[0];
    //           if (key !== "Id") result[key] = elem[key];
    //           return result;
    //         }, {});
    //       });
    //       console.log(dataOrigin, "dataOrigin");
    //       dataOriginCsv = dataOriginCsv.concat(dataOrigin);
    //     })
    //     .catch((err) => {});
    // }
    // console.log(dataOriginCsv, "dataOriginCsv");

    // setDataOriginal(dataOriginCsv);
    csvLink.current && csvLink.current.link && csvLink.current.link.click();
  }, []);

  const onInputChangeEmail = useCallback(
    async (params, value) => {
      const filter = {
        filter: `{"where":{ "column1" : {"regexp": "/.*${value}.*/i"}}}`,
      };
      await API.trackRecords.trackRecordFind(filter).then((result) => {
        setDataEmail(get(result, "data"));
        setEmail(value);
      });
    },
    [API.trackRecords]
  );

  const onInputChangeCivi = useCallback(
    async (params, value) => {
      const filter = {
        filter: `{"where":{ "column2" : {"regexp": "/.*${value}.*/i"}}}`,
      };

      await API.trackRecords.trackRecordFind(filter).then((result) => {
        setDataCivili(get(result, "data"));
        setCivi(value);
      });
    },
    [API.trackRecords]
  );
  const onInputChangePost = useCallback(
    async (params, value) => {
      const filter = {
        filter: `{"where":{ "column5" : {"regexp": "/.*${value}.*/i"}}}`,
      };
      await API.trackRecords.trackRecordFind(filter).then((result) => {
        setDataCode(get(result, "data"));
        setCode(value);
      });
    },
    [API.trackRecords]
  );
  const onInputChangePrenom = useCallback(
    async (params, value) => {
      const filter = {
        filter: `{"where":{ "column3" : {"regexp": "/.*${value}.*/i"}}}`,
      };
      await API.trackRecords.trackRecordFind(filter).then((result) => {
        setDataPrenom(get(result, "data"));
        setPrenom(value);
      });
    },
    [API.trackRecords]
  );

  const onInputChangeNom = useCallback(
    async (params, value) => {
      const filter = {
        filter: `{"where":{ "column4" : {"regexp": "/.*${value}.*/i"}}}`,
      };
      await API.trackRecords.trackRecordFind(filter).then((result) => {
        setDataNom(get(result, "data"));
        setNom(value);
      });
    },
    [API.trackRecords]
  );

  const handleFilter = useCallback(async () => {
    if (email === '' && civi === '' && code === '' && prenom === '' && nom === '' && permissions === "USER" ){
      notify("At least one filter must be specified", "warning");
      return;
    }
    const filter = {
      filter: `{"where":{ "column1" : {"regexp": "/.*${email}.*/i"}, "column2" : {"regexp": "/.*${civi}.*/i"} , "column3" : {"regexp": "/.*${prenom}.*/i"}, "column4" : {"regexp": "/.*${nom}.*/i"}, "column5" : {"regexp": "/.*${code}.*/i"}}}`,
    };
    await API.trackRecords.trackRecordFind(filter).then((data) => {
      const dataColumns = get(data, "data").map((item) => {
        const listKey = Object.keys(item).filter(
          (i) =>
            i !== "batchId" &&
            i !== "id" &&
            i !== "createdAt" &&
            i !== "updatedAt" &&
            i !== "projectId" &&
            i !== "extraColumns"
        );
        return Object.assign(
          {},
          listKey
            .map((itemKey) => {
              return item[`${itemKey}`];
            })
            .filter((i) => i !== null)
        );
      });

      const dataCsv = dataColumns.map((item, index) => {
        return {
          ...item,
          id: index + 1,
        };
      });
      setLoading(false);

      setData(dataCsv);
      if (header.length > 0) {
        const dataColumns = header.map(
          (i, index) => {
            return {
              field: Number(i.id) >= 0 ? i.id : index,
              headerName: i.columnName,
              width: 200,
            };
          }
        );

        dataColumns.unshift({
          field: "id",
          headerName: "Id",
          width: 100,
        });

        setColumns(dataColumns as any);

        const dataOrigin = dataCsv.map((item) => {
          const newOb = dataColumns.map((i) => {
            let key = i.headerName;
            return {
              [key]: item[`${i.field}`],
            };
          });
          return newOb.reduce(function (result, elem) {
            let key = Object.keys(elem)[0];
            if (key !== "Id") result[key] = elem[key];
            return result;
          }, {});
        });
        setDataOriginal(dataOrigin);
      }
    });
  }, [API.trackRecords, civi, code, email, header, nom, notify, permissions, prenom]);

  const onSelectionChange = useCallback((onSelectionChange)=>{
    const dataSelectEP = onSelectionChange.rowIds.map(item => {
      return dataOriginal[item -1]
    })

    setDataExport(dataSelectEP);

  },[dataOriginal])
  return (
    <Fragment>
      <div className="main">
        {useMemo(
          () => (
            <div className="header">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    marginRight: 20,
                  }}
                >
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: 250,
                      background: "rgba(0, 0, 0, 0.04)",
                    }}
                    options={dataEmail.map((item) => get(item, "column1"))}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={onInputChangeEmail}
                    // onChange={onChangeEmail}
                    // onFocus={onFocusEmail}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Search Project"
                        placeholder="Email"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: 250,
                      background: "rgba(0, 0, 0, 0.04)",
                      marginTop: 10,
                    }}
                    options={dataCivili.map((item) => get(item, "column2"))}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={onInputChangeCivi}
                    // onChange={onChangeEmail}
                    // onFocus={onFocusEmail}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Search Project"
                        placeholder="Civilité"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: 250,
                      background: "rgba(0, 0, 0, 0.04)",
                      marginTop: 10,
                    }}
                    options={dataCode.map((item) => get(item, "column5"))}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={onInputChangePost}
                    // onChange={onChangeEmail}
                    // onFocus={onFocusEmail}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Search Project"
                        placeholder="Code postal"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>

                <div style={{ flexDirection: "column", display: "flex" }}>
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: 250,
                      background: "rgba(0, 0, 0, 0.04)",
                    }}
                    options={dataPrenom.map((item) => get(item, "column3"))}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={onInputChangePrenom}
                    // onChange={onChangeEmail}
                    // onFocus={onFocusEmail}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Search Project"
                        placeholder="Prénom"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    id="country-select-demo"
                    style={{
                      width: 250,
                      background: "rgba(0, 0, 0, 0.04)",
                      marginTop: 10,
                    }}
                    options={dataNom.map((item) => get(item, "column4"))}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                      <React.Fragment>{option}</React.Fragment>
                    )}
                    onInputChange={onInputChangeNom}
                    // onChange={onChangeEmail}
                    // onFocus={onFocusEmail}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Search Project"
                        placeholder="Nom"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    // startIcon={<AddIcon />}
                    style={{
                      textTransform: "none",
                      // marginRight: 20,
                      marginTop: 10,
                      maxWidth: 100,
                    }}
                    onClick={handleFilter}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="header-right">
                <Button
                  variant="contained"
                  startIcon={<ImportExportIcon />}
                  style={{
                    background: "transparent",
                    boxShadow: "none",
                    textTransform: "none",
                    marginRight: 20,
                    marginTop: 20,
                    fontWeight: 500,
                    maxWidth: 100,
                    color: "#3f51b5",
                  }}
                  onClick={handleImport}
                >
                  IMPORT
                </Button>
                {dataOriginal.length > 0 && (
                  <div>
                    <CSVLink
                      filename={`fabient`}
                      target="_blank"
                      ref={csvLink}
                      data={dataExport}
                    ></CSVLink>
                    <Button
                      variant="contained"
                      startIcon={<GetAppIcon />}
                      onClick={handleExport}
                      style={{
                        background: "transparent",
                        boxShadow: "none",
                        textTransform: "none",
                        marginRight: 20,
                        marginTop: 20,
                        fontWeight: 500,
                        maxWidth: 100,
                        color: "#3f51b5",
                      }}
                    >
                      EXPORT
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ),
          [classes.option, dataCivili, dataCode, dataEmail, dataExport, dataNom, dataOriginal.length, dataPrenom, handleExport, handleFilter, handleImport, onInputChangeCivi, onInputChangeEmail, onInputChangeNom, onInputChangePost, onInputChangePrenom]
        )}

        {useMemo(
          () =>
            data.length > 0 && (
              <DataGrid
                rows={data}
                onPageChange={onPageChange}
                rowCount={countRow}
                columns={columns}
                page={page}
                checkboxSelection
                onSelectionChange={onSelectionChange}
              />
            ),
          [columns, countRow, data, onPageChange, onSelectionChange, page]
        )}

        {useMemo(
          () =>
            !!loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            ),
          [loading]
        )}
      </div>
    </Fragment>
  );
};
