// in src/Menu.js
import * as React from "react";
import { createElement, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources, useTranslate } from "react-admin";
import { withRouter } from "react-router-dom";
import SubMenu from "./subMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalTaxiIcon from "@material-ui/icons/LocalTaxi";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PaymentIcon from "@material-ui/icons/Payment";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MailIcon from "@material-ui/icons/Mail";
import { usePermissions } from "react-admin";
import { storage } from "../react-admin-loopback";

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu = ({ onMenuClick, dense, logout }) => {
  const { permissions } = usePermissions();
  const open = true;
  const resources: any[] = useSelector(getResources);
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
  });
  const [typeDataLogin, setTypeDataLogin] = useState();

  React.useEffect(() => {
    const user = storage.load("user");
    setTypeDataLogin(user.type);
  }, []);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const translate = useTranslate();
  return (
    <div>
      {permissions === "SUPERADMIN" || permissions === "ADMIN" ? (
        <MenuItemLink
          to="/Accounts"
          primaryText={`${translate("back-office.account")}s`}
          leftIcon={<SupervisorAccountIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        ></MenuItemLink>
      ) : (
        <div />
      )}

      {/* <MenuItemLink
        to="/Projects"
        primaryText={"Projects"}
        leftIcon={<AccountTreeIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink> */}
      <MenuItemLink
        to="/CSV"
        primaryText={"Data"}
        leftIcon={<AssignmentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      ></MenuItemLink>
      {(permissions === "SUPERADMIN" ||
        permissions === "ADMIN" ||
        typeDataLogin === "API") && (
        <MenuItemLink
          to="/apikey"
          primaryText={"Apikey"}
          leftIcon={<AccountTreeIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        ></MenuItemLink>
      )}
    </div>
  );
};

export default withRouter(Menu as any);
