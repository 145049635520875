import React, { useCallback, Fragment, useMemo, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  useRefresh,
  EditButton,
  useRedirect,
} from "react-admin";
import { EditProject } from "./EditProject";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

const ExpandedDetail = (props: any) => {
  const [record, setRecord] = useState(props.record);

  const callback = useCallback(() => {}, []);
  return (
    <Fragment>
      {useMemo(
        () => (
          <EditProject
            record={record}
            headers={record.headers}
            type={null}
            callback={callback}
          />
        ),
        [callback, record]
      )}
    </Fragment>
  );
};

const ViewCSV = (props) => {
  const redirect = useRedirect();
  const viewCSV = useCallback(() => {
    redirect(`/CSV?name=${props.record.name}`);
  }, [props.record.name, redirect]);

  return (
    <Button style={{ color: "#3f51b5" }} type="submit" onClick={viewCSV}>
      View CSV
    </Button>
  );
};

export const ProjectList = (props) => {
  return (
    <List exporter={false} sort={{ field: "createdAt", order: "DESC" }} {...props}>
      <Datagrid
        // rowClick="expand"
        expand={(__props) => (
          <ExpandedDetail {...__props} expanded={props.query} />
        )}
      >
        <TextField source="name" />
        <ReferenceField label="User" source="accountId" reference="Accounts">
          <TextField source="username" />
        </ReferenceField>

        <ViewCSV />
      </Datagrid>
    </List>
  );
};
