import React, { useState, useCallback } from "react";
import { useRedirect, useRefresh } from "react-admin";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import * as sha1 from 'sha1';
import LockIcon from "@material-ui/icons/Lock";
import { Notification } from "react-admin";
import { useLogin, useNotify, useTranslate } from "ra-core";
import { LoopbackAPI } from "../../react-admin-loopback/api/loopback-api";
import { RESET_PASSWORD_CODE } from "../../constant";
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center",
    background: "url(https://source.unsplash.com/random/1600x900)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginBottom: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  code?: string;
}

const { Form } = withTypes<FormValues>();

const PinCode = () => {
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();
  const API = new LoopbackAPI();

  const handleSubmit = async (auth: FormValues) => {
    // setLoading(true);
    const id = sha1(auth.code + RESET_PASSWORD_CODE)
    await API.accountTokens.accountTokenExistsGetAccountTokensIdExists(id).then((respon)=>{
        if (respon && respon.data && !respon.data.exists) {
          notify("code incorrect", "warning");
        }else{
          redirect(`/ChangePasswordPage?token=${id}`)
        }
    }).catch((err) => {
      notify(`${err.error.message}`, "warning");
    })
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.code) {
      errors.code = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>

              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="code"
                    // @ts-ignore
                    component={renderInput}
                    label="Code"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  VERYFY CODE
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

PinCode.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
export const PinCodePage = (props: any) => (
  <ThemeProvider theme={createMuiTheme({})}>
    <PinCode {...props} />
  </ThemeProvider>
);
